import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/theme-chalk/index.css'
import App from './App.vue'
import Konva from 'konva';
import './registerServiceWorker'

const app = createApp(App)
app.use(ElementPlus,{
    locale: zhCn,
})
app.use(Konva)

app.mount('#app')

