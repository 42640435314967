<template>
  <div>
    <el-container style="padding:0px; margin-bottom: 40px">
      <el-aside style="width:200px;">
        <div>
          <div>
            <el-check-tag checked style="width: 150px; padding: 14px 0px 15px 0px; margin: auto; font-size: 15px;">{{roomData[0].roomName}}</el-check-tag>
          </div>

          <div>
            <label class="input-label">试室列数:</label>
            <el-input-number v-model="cols" :min="1" id="cols" :disabled = "addMode === 1"></el-input-number>
          </div>

          <div>
            <label class="input-label">
              纸张方向:
              <el-popover
                  placement="right-start"
                  title="配置说明"
                  :width="260"
                  trigger="hover"
                  popper-class = "markPopperClass"
                  :popper-style = "{fontSize: '13px'}"
                  content="由于页面下方其他说明元素需要定位参照，建议先配置好列数再转换纸张方向。若发现有元素错位，重新来回切换方向即可重新定位。若需要重置元素位置亦同理"
              >
                <template #reference>
                  <el-icon style="color: #3192f8; padding-bottom: 2px; vertical-align: middle"><QuestionFilled /></el-icon>
                </template>
              </el-popover>
            </label>
            <el-radio-group v-model="direction">
              <el-radio-button label="landscape" border :disabled = "addMode === 1">
                <el-space :size="0">
                  <el-icon style="font-size: 15px;">
                    <el-icon-Sort style="transform: rotate(-90deg)"/>
                  </el-icon>
                  横向
                </el-space>
              </el-radio-button>
              <el-radio-button label="portrait" border :disabled = "addMode === 1">
                <el-space :size="0">
                  纵向
                  <el-icon style="font-size: 15px">
                    <el-icon-Sort/>
                  </el-icon>
                </el-space>
              </el-radio-button>
            </el-radio-group>
          </div>

          <div>
            <label class="input-label">排座起点</label>
            <div>
              <el-radio-group v-model="seatOffsetStart">
                <el-radio-button label="TopLeft" border :disabled = "addMode === 1">
                  <el-space :size="0">
                    <el-icon style="font-size: 15px;">
                      <el-icon-TopLeft/>
                    </el-icon>
                    左上
                  </el-space>
                </el-radio-button>
                <el-radio-button label="TopRight" border :disabled = "addMode === 1">
                  <el-space :size="0">
                    右下
                    <el-icon style="font-size: 15px">
                      <el-icon-TopRight/>
                    </el-icon>
                  </el-space>
                </el-radio-button>
              </el-radio-group>
            </div>
            <div style="margin-top: 10px">
              <el-radio-group v-model="seatOffsetStart">
                <el-radio-button label="BottomLeft" border :disabled = "addMode === 1">
                  <el-space :size="0">
                    <el-icon style="font-size: 15px;">
                      <el-icon-BottomLeft/>
                    </el-icon>
                    左下
                  </el-space>
                </el-radio-button>
                <el-radio-button label="BottomRight" border :disabled = "addMode === 1">
                  <el-space :size="0">
                    右下
                    <el-icon style="font-size: 15px">
                      <el-icon-BottomRight/>
                    </el-icon>
                  </el-space>
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>

          <div>
            <label class="input-label">座位宽度:</label>
            <el-input-number v-model="blockWidth" @change="updateGrid" :min="80" :max="500" id="blockWidth"></el-input-number>
          </div>

          <div>
            <label class="input-label">座位高度:</label>
            <el-input-number v-model="blockHeight" @change="updateGrid" :min="55" :max="200" id="blockHeight"></el-input-number>
          </div>

          <div>
            <label for="cellGap" class="input-label">座位间距:</label>
            <el-slider class="slider" v-model="cellGap" @change="updateGrid" :min="0" :max="50" :step="1" id="cellGap"></el-slider>
          </div>

          <div>
            <label for="scaleFactor" class="input-label">座位缩放:</label>
            <el-slider class="slider" v-model="scaleFactor" @change="scaleGrid" :min="0.1" :max="2" :step="0.02" id="scaleFactor"></el-slider>
          </div>

          <div>
              <el-popover
                  placement="right-start"
                  title="自动剔除空行/列"
                  :width="200"
                  trigger="hover"
                  popper-class = "markPopperClass"
                  :popper-style = "{fontSize: '13px'}"
                  content="本设置默认启用，当座位在拖拽新增行或列的过程中，如果出现空行或空列将被系统自动删除"
              >
                <template #reference>
                  <el-button plain :type="removeEmptyStatusType" @click="handleRemoveEmptyStatus" class="button">{{ removeEmptyStatus ? '空行列剔除' : '禁用行列剔除' }}</el-button>
                </template>
              </el-popover>
          </div>

          <div>
            <el-button @click="shuffle" class="button" :disabled = "addMode === 1">手动乱序</el-button>
          </div>

          <div>
            <el-button @click="toggleSelectAll" class="button">{{ selectAll ? '取消选中座位' : '选中所有座位' }}</el-button>
          </div>

          <div>
            <el-button @click="generateOrResetGrid" class="button">重置座位</el-button>
          </div>

          <div>
            <el-button ref="printButton" @click="pdfSave" class="button">单独保存</el-button>
          </div>

<!--          <div>-->
<!--            <el-button ref="printButton" @click="saveData" class="button">数据导出</el-button>-->
<!--          </div>-->

        </div>
      </el-aside>
      <el-main style="width:298mm;padding: 0px">
        <div ref="stageContainer"  :class=" [backgroundClass, direction]"  style="margin:auto; overflow: hidden;  border: 1px solid #c4c3c3; border-radius: 3px"></div>
        <el-dialog
            v-model="dialogVisible"
            title="文本编辑"
            width="30%"
            align-center
            @open = "handleDialogOpen"
        >
          <span>
            <el-input
                v-model="dialogInput"
                :autosize="{ minRows: 2, maxRows: 4 }"
                type="textarea"
            />
            <el-space style="margin-top: 10px">
              <label>文字对齐:</label>
              <el-radio-group v-model="noticeTextAlign" label="fontPosition">
                <el-radio-button label="left">居左</el-radio-button>
                <el-radio-button label="center">居中</el-radio-button>
                <el-radio-button label="right">居右</el-radio-button>
              </el-radio-group>
            </el-space>
            <el-space style="margin-top: 10px">
              <label>字体大小:</label>
              <el-input-number v-model="noticeSettingFontSize" :min="5" :max="200" style="width: 178px"></el-input-number>
            </el-space>
            <el-space style="margin-top: 10px">
              <label>字框宽度:</label>
              <el-input-number v-model="noticeTextWidth" :min="50" :max="1000" style="width: 178px"></el-input-number>
            </el-space>
          </span>

          <template #footer>
            <span class="dialog-footer">
              <el-button @click="dialogVisible = false">取消</el-button>
              <el-button type="primary" @click="dialogConfirm">
                确认
              </el-button>
            </span>
          </template>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  TopLeft, TopRight, BottomLeft, BottomRight, Sort, QuestionFilled, Close
} from '@element-plus/icons-vue'
import { ElMessage, ElLoading } from 'element-plus'
import { jsPDF } from "jspdf";
import {markRaw} from "vue";
export default {
  name: 'seatPosition',
  props: {
    odata: Object,
  } ,
  data() {
    return {
      addMode:0,//手动添加过行列就关闭实时修改列数
      direction:'landscape',
      rows: '',
      cols: 6,
      totalStudent: 56,
      initialRows:'',
      initialCols:'',
      stage: null,
      stageWidth: 297 * 3.78,
      stageHeight: 210 * 3.78,
      layer: null,
      parentLayer: null,
      borderRectW:0,
      cellGap: 8,
      grid: [],
      initialGrid: [],
      seatOffsetStart:'BottomLeft',
      roomData: [],
      draggingItem: null,
      lastHighlightedTarget: null,
      blockDefaultColor:'#ffffff',
      blockDragColor:'#D6EAF8',
      blockHoverColor:'#E0E0E0',
      blockAddRCColor:'#D3D3D3',
      filterColor:'#cecece',
      blockWidth: 168,
      blockHeight: 75,
      seatTextSize:29.526,
      stringTextSize:10.5,
      nameTextSize: 14,
      oIdTextSize: 14,
      roomIdTextSize: 14,
      defaultTextSize: [], //把默认值暂存起来
      titleFontSize:39,
      titleFontWeight: 'bold',
      roomNoticeTextSize1: 21,
      roomNoticeTextSize2: 18,
      examRoomTextAreaSize: 17,
      roomNoticeTextWeight1: '',
      roomNoticeTextWeight2: '',
      editText : {
        titleNoticeText : '',
        roomNoticeText : '',
        roomExamDate: [],
        roomDoorPo: '',
        roomNoticeText2 : '',
      },
      noticeTextAlign: 'center',
      noticeSettingFontSize: 0,
      noticeTextWidth: 0,
      activeTextObj : '',
      dialogInput:'',
      titleNoticeTr : '',
      doubleClickStatus: {'clickTimeout1':null,'clickTimeout2':null,'clickTimeout3':null,'clickTimeout4':null},
      dialogVisible : false,
      scaleFactor: 1,
      // scaleFactor: 0.8,
      selectAll: false,
      preAddedColumn: null,
      preAddedColumnL: null,
      preAddedRow: null,
      preAddedRowB: null,
      backgroundClass: 'normal-background',
      lastDragTime: 0,
      lastDragPosition: { x: 0, y: 0 },
      speedThreshold: 20, // 速度阈值，可以根据需要调整
      rearrangeStatus: false,
      rearrangeMessage: "座位已乱序重排",
      mixArrangeMessage: "混合试室不支持手动重排",
      removeEmptyMessage:"已自动剔除空行空列",
      removeEmptyStatus: true,
      removeEmptyStatusType: 'primary',
    };
  },
  watch: {
    seatOffsetStart(){
      this.initGrid()
      this.updateGrid()
    },
    selectAll(newValue) {
      //this.stage.draggable(newValue);
      this.layer.draggable(newValue);

      this.layer.getChildren().forEach((child) => {
        let hasTextContent = false;
        // 检查child是否是Konva.Group
        if (child instanceof Konva.Group) {
          // 检查每个子元素，查找 Konva.Text 对象
          child.getChildren().forEach((subChild) => {
            if (subChild instanceof Konva.Text && subChild.text() !== '') {
              hasTextContent = true;
            }
          });
        } else if (child instanceof Konva.Text && child.text() !== '') {
          // 如果child直接就是Konva.Text
          hasTextContent = true;
        }

        // 根据是否有文本内容设置是否可拖动
        if (!hasTextContent) {
          child.draggable(false); // 如果没有文本内容，则不允许拖动
        } else {
          child.draggable(!newValue); // 如果有文本内容，根据selectAll的值来设置拖动状态
        }
      });

    },
    cols(){
      if (this.addMode == 0){
        this.stage.position({
          x:0,
          y:0
        })
        this.initGrid()
        this.updateGrid()
      }
      this.borderRectW = this.cols * (this.blockWidth + this.cellGap) + this.cellGap
    },
    blockWidth(newValue) {
      const baseValue = 168
      const textSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[2],1, 15,0.01)
      const seatTextSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[0],20,35,0.05)
      const seatSTextSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[1],1,12,0.01)

      this.nameTextSize = textSize;
      this.oIdTextSize = textSize;
      this.roomIdTextSize = textSize;
      this.seatTextSize = seatTextSize;
      this.stringTextSize = seatSTextSize;

      this.borderRectW = this.cols * (this.blockWidth + this.cellGap) + this.cellGap
    },
    blockHeight(newValue){
      const baseValue = 75
      const textSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[2],1, 15,0.025)
      const seatTextSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[0],20,35,0.09)
      const seatSTextSize = this.calculateTextSize(newValue,baseValue,this.defaultTextSize[1],1,12,0.025)

      this.nameTextSize = textSize;
      this.oIdTextSize = textSize;
      this.roomIdTextSize = textSize;
      this.seatTextSize = seatTextSize;
      this.stringTextSize = seatSTextSize;
    },
    direction(newValue){
      const totalWidth = (this.cols * this.blockWidth + (this.cols + 1) * this.cellGap) * this.scaleFactor;
      const totalHeight = (this.rows * this.blockHeight + (this.rows + 1) * this.cellGap) * this.scaleFactor;
      let textHeight = 55
      if(this.parentLayer.children[5].height() > 45){
        textHeight = this.parentLayer.children[5].height()
      }

      if(newValue == 'landscape'){
        this.stageWidth= 297 * 3.78
        this.stageHeight= 210 * 3.78
        this.stage.size({width : this.stageWidth, height : this.stageHeight})
        this.layer.position({
          x: (this.stageWidth - totalWidth) / 2 ,
          y: (this.stageHeight - totalHeight) / 2,
        });

        this.parentLayer.children[1].x( (this.stageWidth - this.parentLayer.children[1].width()) / 2)
        this.parentLayer.children[1].y( 20)
        this.parentLayer.children[1].fontSize(this.titleFontSize)
        this.parentLayer.children[2].width(800)
        this.parentLayer.children[2].x( (this.stageWidth - this.parentLayer.children[2].width()) / 2 )
        this.parentLayer.children[2].y( 73 )
        this.parentLayer.children[3].width(375)
        this.parentLayer.children[3].x( (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2 + this.blockHeight + 225)
        this.parentLayer.children[3].y( this.stageHeight - 75 )
        this.parentLayer.children[4].x( (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2 + 75)
        this.parentLayer.children[4].y( this.stageHeight - 75 )
        this.parentLayer.children[5].width(410)
        this.parentLayer.children[5].x( this.stageWidth - this.parentLayer.children[5].width() - (this.stageWidth - this.cols * this.blockWidth - (this.cols - 1) * this.cellGap) / 2)
        this.parentLayer.children[5].y( this.stageHeight - 20 - textHeight)
        this.parentLayer.children[5].fontSize(this.examRoomTextAreaSize)
        this.parentLayer.children[6].x( (this.stageWidth - (this.parentLayer.children[6]).children[0].width()) / 2 )
        this.parentLayer.children[6].y( this.stageHeight - (this.parentLayer.children[6]).children[0].height() - 15 )
        this.parentLayer.children[7].visible(true)
        this.parentLayer.children[7].x( (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2 + this.blockHeight + 150)
        this.parentLayer.children[7].y(  this.stageHeight - this.blockHeight - 8)
        this.parentLayer.children[8].visible(true)
        this.parentLayer.children[8].x( (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2)
        this.parentLayer.children[8].y(  this.stageHeight - this.blockHeight - 8)


      }else if(newValue == 'portrait'){
        this.stageWidth= 210 * 3.78
        this.stageHeight= 297 * 3.78
        this.stage.size({width : this.stageWidth, height : this.stageHeight})
        this.layer.position({
          x: (this.stageWidth - totalWidth + this.cellGap) / 2 ,
          y: (this.stageHeight - totalHeight + this.cellGap) / 2,
        });

        this.parentLayer.children[1].x( (this.stageWidth - this.parentLayer.children[1].width()) / 2)
        this.parentLayer.children[1].y( 20)
        this.parentLayer.children[1].fontSize(this.titleFontSize)
        this.parentLayer.children[2].width(500)
        this.parentLayer.children[2].x( (this.stageWidth - this.parentLayer.children[2].width()) / 2 )
        this.parentLayer.children[2].y( 73 )
        this.parentLayer.children[3].width(280)
        this.parentLayer.children[3].x( (this.stageWidth - (this.parentLayer.children[6]).children[0].width()) / 2 -135 )
        this.parentLayer.children[3].y( this.stageHeight - (this.parentLayer.children[6]).children[0].height() - 5 )
        this.parentLayer.children[4].x( (this.stageWidth - (this.parentLayer.children[6]).children[0].width()) / 2 - 265)
        this.parentLayer.children[4].y( this.stageHeight - (this.parentLayer.children[6]).children[0].height() - 5 )
        this.parentLayer.children[5].width(255)
        this.parentLayer.children[5].fontSize(this.examRoomTextAreaSize)
        this.parentLayer.children[5].x( this.stageWidth - this.parentLayer.children[5].width() - (this.stageWidth - this.cols * this.blockWidth - (this.cols - 1) * this.cellGap) / 2 + 18)
        this.parentLayer.children[5].y( this.stageHeight - 33 - textHeight)
        this.parentLayer.children[6].x( (this.stageWidth - (this.parentLayer.children[6]).children[0].width()) / 2 )
        this.parentLayer.children[6].y( this.stageHeight - (this.parentLayer.children[6]).children[0].height() - 20 )
        this.parentLayer.children[7].visible(false)
        this.parentLayer.children[8].visible(false)

        console.log(this.parentLayer.children[5])
      }


    }
  },
  components: {
    'el-icon-TopLeft': TopLeft,
    'el-icon-TopRight': TopRight,
    'el-icon-BottomLeft': BottomLeft,
    'el-icon-BottomRight': BottomRight,
    'el-icon-Sort':Sort,
    QuestionFilled
  },
  created() {
    this.roomData[0] = { ...this.odata };
  },
  mounted() {
    this.direction = this.roomData[0]['direction']
    this.cols = this.roomData[0]['cols']
    this.blockWidth = this.roomData[0]['blockWidth']
    this.blockHeight = this.roomData[0]['blockHeight']
    this.seatOffsetStart = this.roomData[0]['seatOffsetStart']
    this.titleFontSize = this.roomData[0]['titleFontSize']
    this.examRoomTextAreaSize = this.roomData[0]['examRoomTextAreaSize']
    this.stage = new Konva.Stage({
      container: this.$refs.stageContainer,
      width: this.stageWidth,  // A4 dimensions in pixels (assuming 96dpi)
      height: this.stageHeight,
    });
    this.layer = new Konva.Group();
    this.parentLayer = new Konva.Layer();
    this.parentLayer.add(this.layer)
    this.stage.add(this.parentLayer);
    this.totalStudent = this.roomData[0]['stuData'].length
    this.shuffleAndAssignSeats()
    this.initGrid()
    this.stage.on('click', (e) => {
      const borderRect = this.layer.findOne('.borderRect');
      if(e.target instanceof Konva.Stage){
        if (borderRect) {
          const clickPos = this.stage.getPointerPosition();  // 获取点击的坐标
          const rectPos = borderRect.getClientRect();  // 获取虚线矩形的位置和尺寸

          // 判断点击位置是否在虚线矩形的外部
          if (clickPos.x < rectPos.x || clickPos.x > rectPos.x + rectPos.width ||
              clickPos.y < rectPos.y || clickPos.y > rectPos.y + rectPos.height) {
            if (this.selectAll) {
              this.selectAll = false;  // 取消选中状态
              borderRect.destroy();
            }
          }
        }

        if (this.parentLayer.children.some(child => child instanceof Konva.Transformer)){
          this.titleNoticeTr.destroy();
          this.activeTextObj.draggable(false)
        }

      }

    });

    this.generateOrResetGrid()
    this.borderRectW = this.cols * (this.blockWidth + this.cellGap) + this.cellGap
    this.noticeTextHandle(this.editText['titleNoticeText'], 'center', 20, this.doubleClickStatus['clickTimeout1'], this.roomData[0].examTitle, this.titleFontSize, this.titleFontWeight, 'center', 800)
    this.noticeTextHandle(this.editText['roomNoticeText'], 'center', 73, this.doubleClickStatus['clickTimeout2'], this.roomData[0].defaultRoomNoticeText, this.roomNoticeTextSize1, this.roomNoticeTextWeight1, 'center', 450)
    this.noticeTextHandle(this.editText['roomExamDate'], 'date', this.stageHeight - 74.5, this.doubleClickStatus['clickTimeout3'], this.convertDates(this.roomData[0].examDate), this.roomNoticeTextSize2, this.roomNoticeTextWeight1, 'left', 120)
    this.noticeTextHandle(this.editText['roomDoorPo'], 'door', this.stageHeight - 74.5, this.doubleClickStatus['clickTimeout4'], this.roomData[0].doorPO, this.roomNoticeTextSize2, this.roomNoticeTextWeight2, 'left', 120)
    this.noticeTextHandle(this.editText['roomNoticeText2'], 'end', this.stageHeight - 20, this.doubleClickStatus['clickTimeout5'], this.roomData[0].examRoomTextArea, this.examRoomTextAreaSize, this.roomNoticeTextWeight2, 'left', 410)
    this.defaultTextSize = [this.seatTextSize, this.stringTextSize, this.nameTextSize]

    const group = new Konva.Group({
      x: (this.stageWidth - this.blockWidth) / 2,
      y: this.stageHeight - this.blockHeight - 15,
      draggable: false
    });

    const rect = new Konva.Rect({
      width: this.blockWidth,
      height: this.blockHeight,
      fill: '#adadad'
    });

    const rect2 = new Konva.Rect({
      width: this.blockWidth - 12,
      height: this.blockHeight - 12,
      x: 6,
      y: 6,
      stroke: '#4b4b4b',
      strokeWidth: 2,
      fill: '#FFFFFF'
    });

    const text = new Konva.Text({
      text: "讲台",
      fontSize: 30,
      fontStyle: 'bold',
      fill: 'black'
    });

    text.offsetX(- (this.blockWidth - text.width()) / 2)
    text.offsetY(- (this.blockHeight - text.height()) / 2)
    group.add(rect, rect2, text)


    const group2 = new Konva.Group({
      x: (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2 + this.blockHeight + 150,
      y: this.stageHeight - this.blockHeight - 8,
      draggable: false
    });

    const rect3 = new Konva.Rect({
      width: this.blockHeight - 12,
      height: this.blockHeight -12,
      align:'center',
      fill: '#e8e8e8',
    });

    const text2 = new Konva.Text({
      y:12,
      text: "考试\n日期",
      width:this.blockHeight - 12,
      align:'center',
      lineHeight:1,
      fontSize: 20,
      fontStyle: 'bold',
      fill: '#2d2d2d'
    });

    group2.add(rect3,text2)

    const group3 = new Konva.Group({
      x: (this.stageWidth - (this.cols *(this.blockWidth + this.cellGap) - this.cellGap)) / 2,
      y: this.stageHeight - this.blockHeight - 8,
      draggable: false
    });

    const rect4 = new Konva.Rect({
      width: this.blockHeight - 12,
      height: this.blockHeight -12,
      align:'center',
      fill: '#e8e8e8',
    });

    const text3 = new Konva.Text({
      y:12,
      text: "前门\n位置",
      width:this.blockHeight - 12,
      align:'center',
      lineHeight:1,
      fontSize: 20,
      fontStyle: 'bold',
      fill: '#2d2d2d'
    });

    group3.add(rect4,text3)


    this.parentLayer.add(group,group2,group3);

  },

  methods: {
    scaleGrid() {
      this.layer.scale({
        x: this.scaleFactor,
        y: this.scaleFactor
      });

      this.layer.position({
        x: (this.stageWidth - (this.cols * (this.blockWidth + this.cellGap) + this.cellGap) * this.scaleFactor) / 2 ,
        y: (this.stageHeight - (this.rows * (this.blockHeight + this.cellGap) + this.cellGap) * this.scaleFactor) / 2
      });
    },

    generateOrResetGrid() {
      if (this.initialGrid.length) {
        this.grid = JSON.parse(JSON.stringify(this.initialGrid));
        this.rows = this.initialRows;
        this.cols = this.initialCols;
        this.updateGrid();
      } else {
        this.updateGrid();
      }
      this.addMode = 0
    },

    shuffleAndAssignSeats () {
      let stuData = this.roomData[0]['stuData']
      if(this.roomData[0].type !== 'mix'){
        for (let i = stuData.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [stuData[i], stuData[j]] = [stuData[j], stuData[i]];
        }
      }
      stuData.forEach((student, index) => {
        student.seatId = index + 1;
      });
    },

    initGrid() {
      const startCorner = this.seatOffsetStart
      this.rows = Math.ceil(this.totalStudent / this.cols);
      this.grid = Array.from({ length: this.rows }, () => Array.from({ length: this.cols }, () => ({ })));

      let index = 0;
      for (let col = 0; col < this.cols; col++) {
        for (let row = 0; row < this.rows; row++) {
          let adjustedRow = row;
          if (col % 2 === 1) { // 对于奇数列（0开始计数），反转行的顺序
            adjustedRow = this.rows - 1 - row;
          }

          let targetRow = adjustedRow;
          let targetCol = col;

          // 根据起始角调整行列索引
          switch (startCorner) {
            case 'TopRight':
              targetCol = this.cols - 1 - col;
              break;
            case 'BottomLeft':
              targetRow = this.rows - 1 - adjustedRow;
              break;
            case 'BottomRight':
              targetCol = this.cols - 1 - col;
              targetRow = this.rows - 1 - adjustedRow;
              break;
              // 默认为 'TopLeft'
          }

          const student = this.roomData[0]['stuData'][index++];
          this.grid[targetRow][targetCol] = {
            oId: student ? "考号：" + student.oId : '',
            name: student?.name || '',
            seatId: student?.seatId || '',
            roomId: student ? this.roomData[0]['roomName'] : '',
            mark: Array.isArray(student?.mark) ? student.mark : [],
            filter: student?.filter || 0,
          };
        }
      }

      if (this.roomData[0]['fillDirection'] == '1' && this.roomData[0].type != 'mix'){
        if (this.grid[this.grid.length-1][this.grid[this.grid.length-1].length-1].name == ''){
          function findLastIndex(array, predicate) {
            for (let i = array.length - 1; i >= 0; i--) {
              if (predicate(array[i], i, array)) {
                return i;
              }
            }
            return -1;
          }

          for(let index in this.grid[0]){

            if((parseInt(index) + 1) == this.grid.length)break

            if(this.grid[this.grid.length-1-index][this.grid[this.grid.length-1-index].length-1].name == ''){

              if(index % 2 == 0){

                let temp = {}
                const oIndex = this.grid[0].findIndex(element => element.name !== '')
                temp = this.grid[0][oIndex]
                this.grid[0][oIndex] = this.grid[this.grid.length-1-index][this.grid[this.grid.length-1-index].length-1]
                this.grid[this.grid.length-1-index][this.grid[this.grid.length-1-index].length-1] = temp

              }else{

                let temp = {}
                const oIndex = findLastIndex(this.grid[0], element => element.name !== '')
                if (oIndex != -1){
                  temp = this.grid[0][oIndex]
                  this.grid[0][oIndex] = this.grid[this.grid.length-1-index][this.grid[this.grid.length-1-index].length-1]
                  this.grid[this.grid.length-1-index][this.grid[this.grid.length-1-index].length-1] = temp
                }
              }
            }
          }

          this.rearrangeSeats(0)
        }
      }

    },

    rearrangeSeats(status) {
      let seatId = 1;
      let rows = this.grid.length;
      let cols = this.grid[0].length;

      const isNotEmpty = (element) => element.name !== "";

      for (let col = 0; col < cols; col++) {
        for (let row = 0; row < rows; row++) {
          let adjustedRow = row;
          if (col % 2 === 1) {
            adjustedRow = rows - 1 - row;
          }

          let targetRow = adjustedRow;
          let targetCol = col;

          switch (this.seatOffsetStart) {
            case 'TopRight':
              targetCol = cols - 1 - col;
              break;
            case 'BottomLeft':
              targetRow = rows - 1 - adjustedRow;
              break;
            case 'BottomRight':
              targetCol = cols - 1 - col;
              targetRow = rows - 1 - adjustedRow;
              break;
          }

          let element = this.grid[targetRow][targetCol];
          if (isNotEmpty(element)) {
            element.seatId = seatId++;
          }
        }
      }
      this.updateGrid()

      if(status == 0)return
      ElMessage({
        showClose: false,
        message: '座位号已自动更新',
        type: 'success',
        duration: 2000,
      })
    },

    shuffle(){
      this.shuffleAndAssignSeats()
      this.initGrid()
      this.updateGrid()
      if(this.roomData[0].type !== 'mix'){
        ElMessage({
          showClose: false,
          message: this.rearrangeMessage,
          type: 'success',
          duration: 2000,
        })
      }else{
        ElMessage({
          showClose: false,
          message: this.mixArrangeMessage,
          type: 'error',
          duration: 2000,
        })
      }
    },

    updateGrid() {
      this.layer.destroyChildren();
      if (!this.initialGrid.length) {
        this.initialGrid = JSON.parse(JSON.stringify(this.grid));
        this.initialRows = this.rows;
        this.initialCols = this.cols;
      }

      const totalWidth = this.cols * (this.blockWidth + this.cellGap) + this.cellGap;
      const totalHeight = this.rows * (this.blockHeight + this.cellGap) + this.cellGap;

      // 设置Layer的位置
      if(this.addMode == 0){
        this.layer.position({
          x: (this.stageWidth - totalWidth) / 2 ,
          y: (this.stageHeight - totalHeight) / 2
        });
      }

      this.scaleGrid();
      const width = this.blockWidth;
      const height = this.blockHeight;

      const line1 = new Konva.Line({
        points: [width / 2, height / 3, width, height / 3],
        stroke: '#000000',
        strokeWidth: 1
      });

      const line2 = new Konva.Line({
        points: [width / 2, 0, width / 2, height/3*2],
        stroke: '#000000',
        strokeWidth: 1
      });

      const line3 = new Konva.Line({
        points: [0, height/3*2, width, height/3*2],
        stroke: '#000000',
        strokeWidth: 1
      });

      function textAlign(text, position, seatText) {
        const halfWidth = width / 2;

        const textWidth = text.width();
        const textHeight = text.height();

        switch (position){
          case 'topLeft':
            text.x( halfWidth / 2 - textWidth / 2);
            text.y( ( height / 3 * 2 - textHeight ) / 4 );
            break;
          case 'topRight':
            text.x( halfWidth + halfWidth / 2 - textWidth / 2);
            text.y( (height / 3 - textHeight) / 2);
            break;
          case 'topRight2':
            text.x( halfWidth + halfWidth / 2 - textWidth / 2);
            text.y( height / 3 * 1.5  - textHeight / 2);
            break;
          case 'bottom':
            text.x( (width - textWidth) / 2);
            text.y( height - (height / 3 - textHeight) / 2 - textHeight);
            break;
          case 'label':
            text.x( halfWidth / 2 - textWidth / 2);
            text.y( height / 3 * 2 - textHeight - ( height / 3 * 2 - seatText.height() ) / 4);
            break;
        }

      }

      let count = 1;

      for (let i = this.rows - 1; i >= 0; i--) {
        for (let j = 0; j < this.cols; j++) {

          const group = new Konva.Group({
            x: j * (width + this.cellGap) + this.cellGap,
            y: i * (height + this.cellGap) + this.cellGap,
            draggable: true
          });

          const rect = new Konva.Rect({
            width: this.blockWidth,
            height: this.blockHeight,
            fill: '',
            originalFill: '',
            stroke: '#000000',
            strokeWidth: 2
          });

          group.add(rect)

          const student = this.grid[i][j];

          if(student.name != '' ){

            const text1 = new Konva.Text({
              text: student.seatId,
              fontSize: this.seatTextSize,
              fontStyle: 'bold',
              fill: 'black'
            });

            const text2 = new Konva.Text({
              text: '',
              fontSize: this.stringTextSize,
              fill: 'black',
              // width: 80,
            });

            if(student ?.mark.length != 0 && student.mark[0] != '座位号'){
              let tempItem = ''
              student.mark.forEach((item, index)=> {
                tempItem += item
                if(index !== student.mark.length - 1)tempItem += ' | '
              })
              text2.text(tempItem)
              if(student.filter == 1){
                rect.fill(this.filterColor)
                rect.attrs.originalFill= this.filterColor
              }
            }else {
              text2.text('座位号')
              rect.fill(this.blockDefaultColor)
              rect.attrs.originalFill=this.blockDefaultColor
            }

            const text3 = new Konva.Text({
              text: student.name,
              fontSize: this.nameTextSize,
              fill: 'black'
            });

            const text4 = new Konva.Text({
              text: student.roomId,
              fontSize: this.roomIdTextSize,
              fill: 'black'
            });

            const text5 = new Konva.Text({
              text: student.oId ,
              fontSize: this.oIdTextSize,
              fill: 'black'
            });

            textAlign(text1,'topLeft')
            textAlign(text2,'label', text1)
            textAlign(text3,'topRight')
            textAlign(text4,'topRight2')
            textAlign(text5,'bottom')

            group.add(line1.clone(), line2.clone(), line3.clone(), text1, text2, text3, text4, text5);
          }

          group.on('dragstart', this.handleDragStart);
          group.on('dragmove', this.handleDragMove);
          group.on('dragend', this.handleDragEnd);


          this.layer.add(group);
          count++;
        }
      }
      this.checkBlockDraggable();
      this.selectAll = false
    },

    handleDragStart(e){
      this.draggingItem = e.target;
      this.initialPosition = {
        x: e.target.x(),
        y: e.target.y()
      };
      e.target.moveToTop();
      e.currentTarget.findOne('Rect').fill(this.blockDragColor)
      this.backgroundClass = 'selected-background';
    },

    handleDragMove(e){
      const currentTarget = this.detectOverlap(e.target);

      const currentTime = Date.now();
      const currentPos = { x: e.target.x(), y: e.target.y() };

      if (this.lastDragTime !== 0) {
        const timeDiff = currentTime - this.lastDragTime; // 时间差
        const posDiff = Math.sqrt(Math.pow(currentPos.x - this.lastDragPosition.x, 2) + Math.pow(currentPos.y - this.lastDragPosition.y, 2)); // 位置变化

        if (timeDiff > 0) { // 避免除以零
          const speed = posDiff / (timeDiff / 1000);
          if (speed < this.speedThreshold) {
            if (currentTarget && currentTarget !== this.draggingItem && currentTarget.children.length > 0) {
              currentTarget.children[0].fill(this.blockHoverColor);
              this.lastHighlightedTarget = currentTarget;
            }
          }
        }
      }

      this.lastDragTime = currentTime;
      this.lastDragPosition = currentPos;

      if (this.lastHighlightedTarget && this.lastHighlightedTarget !== currentTarget && this.lastHighlightedTarget !== this.draggingItem) {
        if (this.lastHighlightedTarget.children.length > 0) {
          this.lastHighlightedTarget.children[0].fill(this.lastHighlightedTarget.children[0].attrs.originalFill);
        }
        this.lastHighlightedTarget = null;
      }

      if (e.target.x() + this.blockWidth * 0.5 > this.borderRectW && !this.preAddedColumn) {
        this.addPreColumn();
      } else if (e.target.x() + this.blockWidth * 0.5 <= this.borderRectW && this.preAddedColumn) {
        this.cancelPreColumn();
      }

      if (e.target.x() < - this.blockWidth * 0.5 && !this.preAddedColumnL ) {
        this.addPreColumnL()
      } else if (e.target.x() >= - this.blockWidth * 0.5 && this.preAddedColumnL){
        this.cancelPreColumnL()
      }

      if (e.target.y() + this.blockHeight * 0.5 < 0 && !this.preAddedRow) {
        this.addPreRow();
      } else if (e.target.y() + this.blockHeight * 0.5 >= 0 && this.preAddedRow) {
        this.cancelPreRow();
      }

      if(e.target.y() + this.blockHeight > (this.blockHeight + this.cellGap ) * (this.rows) + this.blockHeight * 0.5 && !this.preAddedRowB){
        this.addPreRowB()
      } else if (e.target.y() + this.blockHeight <= (this.blockHeight + this.cellGap ) * (this.rows) + this.blockHeight * 0.5 && this.preAddedRowB){
        this.cancelPreRowB()
      }

      e.currentTarget.moveToTop();
    },

    handleDragEnd(e){
      const group = e.target;

      const target = this.detectOverlap(group);

      if (target) {
        const dragEndPos = this.initialPosition;
        const targetPos = { x: target.x(), y: target.y() };

        if (this.preAddedRow) {
          targetPos.y += this.blockHeight +  this.cellGap;
          dragEndPos.y += this.blockHeight + this.cellGap;
          this.layer.y(this.layer.y() - (this.blockHeight + this.cellGap) * this.scaleFactor);
        }

        if (this.preAddedColumnL) {
          targetPos.x += this.blockWidth + this.cellGap;
          dragEndPos.x += this.blockWidth + this.cellGap;
          this.layer.x( this.layer.x() - (this.blockWidth + this.cellGap) * this.scaleFactor)
        }


        group.to({
          x: targetPos.x,
          y: targetPos.y,
          duration: 0.2
        });

        target.to({
          x: dragEndPos.x,
          y: dragEndPos.y,
          duration: 0.2,
          onFinish: () => {
            this.updateGridFromBlocks();
            this.removeEmptyRowsAndColumns();
            this.rearrangeSeats()
            group.children[0]?.fill(group.children[0].attrs.originalFill)
            target.children[0]?.fill(target.children[0].attrs.originalFill)
          }
        });
      } else {
        const closestRow = Math.round((group.y() - this.cellGap) / (this.blockHeight + this.cellGap));
        const closestCol = Math.round((group.x() - this.cellGap) / (this.blockWidth + this.cellGap));
        const snappedX = closestCol * (this.blockWidth + this.cellGap) + this.cellGap;
        const snappedY = closestRow * (this.blockHeight + this.cellGap) + this.cellGap;

        group.to({
          x: snappedX,
          y: snappedY,
          duration: 0.2,
          onFinish: () => {}
        });
      }

      if (this.preAddedColumn && e.target.x() + this.blockWidth > this.borderRectW) {
        this.confirmPreColumn();
      } else if (this.preAddedColumn) {
        this.cancelPreColumn();
      }

      if (this.preAddedColumnL && e.target.x() < - this.blockWidth * 0.5) {
        this.confirmPreColumnL()
      }
      else if (this.preAddedColumnL){
        this.cancelPreColumnL()
      }

      if (this.preAddedRow && e.target.y() - this.blockHeight < 0) {
        this.confirmPreRow();
      } else if (this.preAddedRow) {
        this.cancelPreRow();
      }

      if(this.preAddedRowB && e.target.y() + this.blockHeight > (this.blockHeight + this.cellGap ) * (this.rows) + this.blockHeight * 0.5){
        this.confirmPreRowB()
      } else if (this.preAddedRowB){
        this.cancelPreRowB()
      }

      this.backgroundClass = 'normal-background';
    },

    updateGridFromBlocks() {
      let newGrid = Array.from({ length: this.rows }, () => Array.from({ length: this.cols }, () => ({ name: '' })));

      this.layer.children.forEach((child) => {
        if (child.name() !== 'borderRect' && child.children && child.children.length > 1) {
          let rowIndex = Math.round((child.y() - this.cellGap) / (this.blockHeight + this.cellGap));
          let colIndex = Math.round((child.x() - this.cellGap) / (this.blockWidth + this.cellGap));

          // 考虑新增列和行的情况
          if (this.preAddedColumnL) {
            colIndex += 1;
          }

          if (this.preAddedRowB) {
            rowIndex += 1;
          }

          // 检查当前行和列是否在新的网格范围内
          if (rowIndex >= 0 && rowIndex < newGrid.length && colIndex >= 0 && colIndex < newGrid[rowIndex].length) {
            // 处理空框框和非空框框的逻辑
            if (child.children[6].text() == "") {
              newGrid[rowIndex][colIndex] = {
                oId: "",
                name: "",
                seatId: "",
                roomId: "",
              };
            } else {
              let filter = 0
              if(child.findOne('Rect').attrs.originalFill == this.filterColor)filter = 1

              newGrid[rowIndex][colIndex] = {
                oId: child.children[8].text(),
                name: child.children[6].text(),
                seatId: child.children[4].text(),
                roomId: child.children[7].text(),
                mark: [child.children[5].text()],
                filter: filter
              };
            }
          }
        }
      });

      this.grid = newGrid;
    },

    handleRemoveEmptyStatus(){
      this.removeEmptyStatus = !this.removeEmptyStatus
      if(this.removeEmptyStatus == false){
        this.removeEmptyStatusType = "danger"
      }else {
        this.removeEmptyStatusType = "primary"
      }
    },

    removeEmptyRowsAndColumns() {
      if(this.removeEmptyStatus){
        let emptyRows = [];
        let emptyColumns = new Array(this.cols).fill(true);

        // 检测空行和标记非空列
        for (let i = 0; i < this.grid.length; i++) {
          let rowIsEmpty = true;
          for (let j = 0; j < this.cols; j++) {
            if (this.grid[i][j].name || this.grid[i][j].oId || this.grid[i][j].seatId || this.grid[i][j].roomId) {
              rowIsEmpty = false;
              emptyColumns[j] = false; // 当前列不为空
            }
          }
          if (rowIsEmpty) {
            emptyRows.push(i);
          }
        }

        // 删除空行
        for (let i = emptyRows.length - 1; i >= 0; i--) {
          this.grid.splice(emptyRows[i], 1);
        }
        this.rows -= emptyRows.length; // 更新行数

        // 删除空列
        for (let j = emptyColumns.length - 1; j >= 0; j--) {
          if (emptyColumns[j]) {
            for (let row of this.grid) {
              row.splice(j, 1);
            }
            this.cols--; // 更新列数
          }
        }

        // 如果存在空行或列，更新 layer
        if (emptyRows.length > 0 || emptyColumns.some(isEmpty => isEmpty)) {
          this.updateGrid();
          ElMessage(this.removeEmptyMessage)
        }
      }
    },

    checkBlockDraggable() {
      this.layer.getChildren().forEach((child) => {
        if (child instanceof Konva.Group) {
          let hasTextContent = false;

          child.getChildren().forEach((subChild) => {

            if (subChild instanceof Konva.Text && subChild.text() !== '') {
              hasTextContent = true;
            }
          });

          // 根据是否有文本内容设置是否可拖动
          child.draggable(hasTextContent);
        }
      });
    },

    detectOverlap(group) {
      let overlapItem = null;

      // 计算正在拖动的组件的中心点位置
      const centerX = group.x() + group.children[0].width() / 2;
      const centerY = group.y() + group.children[0].height() / 2;

      const children = this.layer.getChildren();

      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        // if(!(child instanceof Konva.Text)){
          if (child.name() === 'borderRect' || child === group) continue;

          const r2 = {
            x: child.x(),
            y: child.y(),
            width: child.children[0].width(),
            height: child.children[0].height()
          };

          // 检查中心点是否在其他组件的边界内
          if (centerX > r2.x && centerX < r2.x + r2.width &&
              centerY > r2.y && centerY < r2.y + r2.height) {
            overlapItem = child;
            break;
          }
        // }

      }
      return overlapItem;
    },

    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        const borderRect = new Konva.Rect({
          x: 0,
          y: 0,
          width: this.cols * (this.blockWidth + this.cellGap) + this.cellGap,
          height: this.rows * (this.blockHeight + this.cellGap) + this.cellGap,
          fill:'#F5F5F5',
          stroke: 'black',
          strokeWidth: 2,
          dash: [10, 5],
          name: 'borderRect'
        });
        this.layer.add(borderRect);
        borderRect.moveToBottom()
      } else {
        const borderRect = this.layer.findOne('.borderRect');
        if (borderRect) {
          borderRect.destroy();
        }
      }
    },

    addPreColumn() {
      if (!this.preAddedColumn) {
        this.preAddedColumn = [];
        const newColIndex = this.cols ;
        for (let i = 0; i < this.rows; i++) {
          const group = new Konva.Group({
            x: newColIndex * (this.blockWidth + this.cellGap) + this.cellGap,
            y: i * (this.blockHeight + this.cellGap) + this.cellGap,
          });

          const rect = new Konva.Rect({
            width: this.blockWidth,
            height: this.blockHeight,
            fill: this.blockAddRCColor,
            originalFill: this.blockDefaultColor,
            stroke: '#000000',
            strokeWidth: 2
          });

          group.add(rect);
          this.layer.add(group);
          this.preAddedColumn.push(group);
        }
      }
    },

    confirmPreColumn() {
      if (this.preAddedColumn) {
        this.cols++;  // 列数增加

        this.preAddedColumn.forEach(group => {
          // 更新方块的x坐标以匹配新的列位置
          const newColIndex = this.cols - 1;
          const newX = newColIndex * (this.blockWidth + this.cellGap) + this.cellGap;
          group.x(newX);
          group.children[0]?.fill(group.children[0].attrs.originalFill)
        });

        this.preAddedColumn = null;  // 清空预添加的方块数组

        this.updateBorderRectW()

        this.addMode = 1
      }
    },

    cancelPreColumn() {
      if (this.preAddedColumn) {
        this.preAddedColumn.forEach(group => group.destroy());
        this.preAddedColumn = null;
      }
    },

    addPreColumnL() {
      if (!this.preAddedColumnL) {
        this.preAddedColumnL = [];
        for (let i = 0; i < this.rows; i++) {
          const group = new Konva.Group({
            x: - this.blockWidth,
            y: i * (this.blockHeight + this.cellGap) + this.cellGap,
          });

          const rect = new Konva.Rect({
            width: this.blockWidth,
            height: this.blockHeight,
            fill: this.blockAddRCColor,
            originalFill: this.blockDefaultColor,
            stroke: '#000000',
            strokeWidth: 2
          });

          group.add(rect);
          this.layer.add(group);
          this.preAddedColumnL.push(group);
        }
      }
    },

    confirmPreColumnL() {
      if (this.preAddedColumnL) {
        const draggedBlock = this.draggingItem;
        const swappedBlock = this.detectOverlap(draggedBlock);

        this.cols++;  // 列数增加

        this.preAddedColumnL.forEach(group => {
          // 更新方块的x坐标以匹配新的列位置
          const newX = -this.blockWidth;
          group.x(newX);
          group.children[0]?.fill(group.children[0].attrs.originalFill)
        });

        this.preAddedColumnL = null;  // 清空预添加的方块数组

        this.layer.getChildren().forEach((child) => {
          if (child.name() !== 'borderRect') {
            child.x(child.x() + this.blockWidth + this.cellGap);
          }
        });

        this.updateBorderRectW();

        this.addMode = 1;
      }
    },

    cancelPreColumnL() {
      if (this.preAddedColumnL) {
        this.preAddedColumnL.forEach(group => group.destroy());
        this.preAddedColumnL = null;
      }
    },

    addPreRow() {
      if (!this.preAddedRow) {
        this.preAddedRow = [];
        for (let j = 0; j < this.cols; j++) {
          const group = new Konva.Group({
            x: j * (this.blockWidth + this.cellGap) + this.cellGap,
            y: - (this.blockHeight),
            width: this.blockWidth,
            height: this.blockHeight,
          });

          const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.blockWidth,
            height: this.blockHeight,
            fill: this.blockAddRCColor,
            originalFill: this.blockDefaultColor,
            stroke: '#000000',
            strokeWidth: 2
          });

          group.add(rect);
         this.layer.add(group);
          this.preAddedRow.push(group);
        }
      }
    },

    cancelPreRow() {
      if (this.preAddedRow) {
        this.preAddedRow.forEach(group => group.destroy());
        this.preAddedRow = null;
      }
    },

    confirmPreRow() {
      if (this.preAddedRow) {
        // 获取被拖动和被交换的方块
        const draggedBlock = this.draggingItem;
        const swappedBlock = this.detectOverlap(draggedBlock);

        this.rows++;  // 行数增加

        const currentLayerY = this.layer.y();

        // 更新预添加的方块的y坐标以匹配新的行位置
        this.preAddedRow.forEach((group, index) => {
          const newRowY = -this.blockHeight;  // 新行的y坐标应为0
          group.y(newRowY);
          group.children[0]?.fill(group.children[0].attrs.originalFill)
        });
        this.preAddedRow = null;  // 清空预添加的方块数组

        // 移动所有的方块，使它们下移一个位置
        this.layer.getChildren().forEach((child) => {
          if (child.name() !== 'borderRect') {
            child.y(child.y() + this.blockHeight + this.cellGap);
          }
        });

        // 再次下移被拖动的方块和被交换的方块
        if (draggedBlock) {
          draggedBlock.y(draggedBlock.y() + this.blockHeight + this.cellGap);
        }
        if (swappedBlock) {
          swappedBlock.y(swappedBlock.y() + this.blockHeight + this.cellGap);
        }

        this.updateBorderRectW();
        this.addMode = 1
      }
    },

    addPreRowB() {
      if (!this.preAddedRowB) {
        this.preAddedRowB = [];
        for (let j = 0; j < this.cols; j++) {
          const group = new Konva.Group({
            x: j * (this.blockWidth + this.cellGap) + this.cellGap,
            y: this.rows * (this.blockHeight + this.cellGap) + this.cellGap,
            width: this.blockWidth,
            height: this.blockHeight,
          });

          const rect = new Konva.Rect({
            x: 0,
            y: 0,
            width: this.blockWidth,
            height: this.blockHeight,
            fill: this.blockAddRCColor,
            originalFill: this.blockDefaultColor,
            stroke: '#000000',
            strokeWidth: 2
          });

          group.add(rect);
          this.layer.add(group);
          this.preAddedRowB.push(group);
        }
      }
    },

    cancelPreRowB() {
      if (this.preAddedRowB) {
        this.preAddedRowB.forEach(group => group.destroy());
        this.preAddedRowB = null;
      }
    },

    confirmPreRowB() {
      if (this.preAddedRowB) {

        this.rows++;  // 行数增加

        this.preAddedRowB.forEach((group, index) => {
          group.children[0]?.fill(group.children[0].attrs.originalFill)
        })

        this.preAddedRowB = null;  // 清空预添加的方块数组

        this.addMode = 1
      }
    },

    updateBorderRectW() {
      // 更新 layer 和 stage 的宽度
      const newLayerWidth = this.cols * (this.blockWidth + this.cellGap) + this.cellGap;
      const newLayerHeight = this.rows * (this.blockHeight + this.cellGap) + this.cellGap;  // 计算新的高度


      //查找并更新边框矩形的尺寸
      const borderRect = this.layer.findOne('.borderRect');
      if (borderRect) {
        borderRect.width(newLayerWidth);
        borderRect.height(newLayerHeight);  // 更新边框矩形的高度
      }


    },

    noticeTextHandle(textObj, positionX, positionY, clickTimeout, defaultText, fontSize, fontWeight, alignD, textWidth){
      textObj = new Konva.Text({
        text: defaultText ,
        fontSize: fontSize,
        fontStyle: fontWeight,
        fill: 'black',
        draggable: false,
        align: alignD,
        width: textWidth,
        lineHeight: 1.3,
      });
      if(positionX === 'center'){
        textObj.position({
          x: (this.stageWidth - textObj.width())/2,
          y: positionY
        })
      }else if(positionX === 'end'){
        let textHeight = 55
        if(textObj.height() > 45){
          textHeight = textObj.height()
        }
        textObj.position({
          x: this.stageWidth - textObj.width() - (this.stageWidth - this.cols * this.blockWidth - (this.cols - 1) * this.cellGap) / 2,
          y: positionY - textHeight
        })
      }else if(positionX === 'date'){
        textObj.position({
          x:(this.stageWidth - (this.cols * ( this.blockWidth + this.cellGap) - this.cellGap)) / 2 + this.blockHeight * 2 + 150,
          y:positionY,
        })
      }else if(positionX === 'door'){
        textObj.position({
          x:(this.stageWidth - (this.cols * ( this.blockWidth + this.cellGap) - this.cellGap)) / 2 + this.blockHeight,
          y:positionY,
        })
      }else{
        textObj.position({
          x: positionX,
          y: positionY
        })
      }

      this.parentLayer.add(textObj)

      const MIN_WIDTH = 20;

      textObj.on('transform', () => {
        textObj.setAttrs({
          width: Math.max(textObj.width() * textObj.scaleX(), MIN_WIDTH),
          scaleX: 1,
          scaleY: 1,
        });
      });

      textObj.on('click',(e)=>{
        if (clickTimeout) {
          clearTimeout(clickTimeout);
          clickTimeout = null;
          return;
        }

        clickTimeout = setTimeout(() => {
          clickTimeout = null;

          if(!this.parentLayer.children.some(child => child instanceof Konva.Transformer)){
            this.titleNoticeTr = new Konva.Transformer({
              nodes: [textObj],
              padding: 5,
              flipEnabled: false,
              borderStroke: '#92c5fa',
              borderStrokeWidth: 1.5,
              anchorStroke: '#0259b2',
              anchorFill: '#649bd4',
              anchorStrokeWidth: 1,
              anchorSize: 10,
              anchorCornerRadius: 2,
              rotateEnabled:false,
              keepRatio: false,
              enabledAnchors: ['middle-left', 'middle-right'],
            });
            this.parentLayer.add(this.titleNoticeTr);
            textObj.draggable(true)
          }else{
            if(this.titleNoticeTr.nodes().filter(node => node instanceof Konva.Text).map(textNode => textNode.text())[0] != e.target.text()){
              this.titleNoticeTr.destroy();
              this.activeTextObj.draggable(false)
              this.titleNoticeTr = new Konva.Transformer({
                nodes: [textObj],
                padding: 5,
                flipEnabled: false,
                borderStroke: '#92c5fa',
                borderStrokeWidth: 1.5,
                anchorStroke: '#0259b2',
                anchorFill: '#649bd4',
                anchorStrokeWidth: 1,
                anchorSize: 10,
                anchorCornerRadius: 2,
                rotateEnabled:false,
                keepRatio: false,
                enabledAnchors: ['middle-left', 'middle-right'],
              });
              this.parentLayer.add(this.titleNoticeTr);
              textObj.draggable(true)
            }
          }

          }, 200);

        this.activeTextObj = textObj
      })

      textObj.on('dblclick',(e)=>{

        this.dialogVisible = true

        this.dialogInput = textObj.text()

        this.activeTextObj = textObj

        if (clickTimeout) {
          clearTimeout(clickTimeout);
          clickTimeout = null;
        }

      })

    },

    handleDialogOpen(e){
      this.noticeTextAlign = this.activeTextObj.align()
      this.noticeSettingFontSize = this.activeTextObj.fontSize()
      this.noticeTextWidth = this.activeTextObj.width()
    },

    dialogConfirm(){
      this.dialogVisible = false
      this.activeTextObj.text(this.dialogInput)
      this.activeTextObj.align(this.noticeTextAlign)
      this.activeTextObj.fontSize(this.noticeSettingFontSize)
      this.activeTextObj.width(this.noticeTextWidth)
      if (this.titleNoticeTr){
        this.titleNoticeTr.destroy();
        this.activeTextObj.draggable(false)
      }
    },

    calculateTextSize(newValue,baseValue, defaultSize, minSize, maxSize, scaleFactor){
      // scaleFactor缩放因子，用于调整指数函数的影响程度
      let textSize;
      if (newValue >= baseValue) {
        let increaseFactor = Math.exp(scaleFactor * (newValue - baseValue));
        if(increaseFactor > 0)textSize = defaultSize + increaseFactor;

        // 限制字体大小不超过某个最大值
        textSize = Math.min(textSize, maxSize);
      } else {

        let decreaseFactor = Math.exp(scaleFactor * (baseValue - newValue));
        if(decreaseFactor > 0)textSize = defaultSize - decreaseFactor;

        // 确保字体大小不小于最小值
        textSize = Math.max(textSize, minSize);
      }
      return textSize
    },

    numberToChinese(number) {
      const chineseNumbers = ['〇', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      return number.toString().split('').map(digit => chineseNumbers[parseInt(digit, 10)]).join('');
    },

    convertDates(dates) {
      if (dates){
        const startDate = new Date(dates[0]);
        const endDate = new Date(dates[1]);

        function padTo2Digits(num) {
          return num.toString().padStart(2, '0');
        }

        const Year = `${this.numberToChinese(startDate.getUTCFullYear())}年`

        const startFormat = `${padTo2Digits(startDate.getUTCMonth() + 1)}.${padTo2Digits(startDate.getDate())}`;

        const endFormat = `${padTo2Digits(endDate.getUTCMonth() + 1)}.${padTo2Digits(endDate.getDate())}`;

        return `${Year}\n${startFormat} ~ ${endFormat}`;
      }

    },

    pdfSave(){
      const loadingInstance = ElLoading.service({
        fullscreen: true ,
        lock: true,
        text: '正在生成 PDF...',
      })

      setTimeout(() => {
        let dataUrl = this.stage.toDataURL({pixelRatio: 3});
        const pdf = new jsPDF({
          orientation: this.direction,
          unit: 'px',
          format: [this.stageWidth, this.stageHeight]
        });

        const margins = {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20
        };

        // 计算内容区域的宽度和高度
        const contentWidth = pdf.internal.pageSize.getWidth() - margins.left - margins.right;
        const contentHeight = pdf.internal.pageSize.getHeight() - margins.top - margins.bottom;

        pdf.addImage(dataUrl, 'PNG', margins.left, margins.top, contentWidth, contentHeight);

        try {
          pdf.save(this.roomData[0].roomName+'.pdf');
        } catch (error) {
          console.error('PDF生成出错:', error);
          ElMessage({
            message: 'PDF生成出错',
            type: 'error',
            duration: 1000,
          })
          loadingInstance.close();
        } finally {
          loadingInstance.close();
          if (this.$refs.printButton && this.$refs.printButton.$el) {
            this.$refs.printButton.$el.blur();
          }
        }

      },100);
    },

    saveData(){
      let temp = []
      this.grid.forEach(item=>{
        item.forEach(oitem=>{
          if(oitem?.name != '')temp.push(oitem)
        })
      })
    },

  }
}
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

aside{
  text-align: center;
}

.normal-background {
  background-color: white;
}

.selected-background {
  /*background-color: lightgrey;*/
  background-color: #F5F5F5;
}

.landscape{
  width:297mm;
  height:210mm;
}

.portrait{
  width:210mm;
  height:297mm;
}

.input-label {
  display: block;
  width: 150px;
  margin: 8px auto 8px auto;
  font-size: 14px;
  text-align: left;
}

.button{
  width: 150px;
  margin: 8px auto 8px auto;
}


.slider{
  width: 150px;
  margin: auto;
}

</style>
