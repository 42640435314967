<template>
  <div id="app">
    <el-container style="padding:0px; margin: auto; width: 1500px;">
      <el-drawer id="elDrawer" v-model="drawerShow" direction="ltr" :close-on-press-escape="false" :size="drawerWidth" :show-close="false" :close-on-click-modal="closeDrawer" @opened="showDom = true">
        <template #header>
          <div style="position: relative; paddingBottom: 15px;">
            <h1 style="display:inline-block; font-weight: bolder; color: #424242"><el-icon style="padding:5px; vertical-align: sub; font-size: 25px; color: #3782D0FF; background-color: #dcecfc; border-radius: 5px;"><Tools/></el-icon> 考场全局配置</h1>
            <div style="position:absolute; top:15px; right:-20px; width:400px; backdrop-filter: blur(15px); border-radius: 3px">
              <el-steps id="elSteps" :space="100" :active="stepsActive" align-center finish-status="finish">
                <el-step title="考试信息" />
                <el-step title="考生导入" />
                <el-step title="选科配置" />
                <el-step title="试室分配" />
              </el-steps>
            </div>
          </div>
        </template>
        <template #default>
          <el-card shadow="never" style="position:relative;overflow:visible;border-top-left-radius: 0px;">
            <el-card class="noticeCard" shadow="never" style="position:relative;overflow:visible;border:unset; border-radius: 0px 3px 0px 0px; margin-bottom: 5px; background-color: #f4f7fd; box-sizing: border-box;">
              <div style="position: absolute;top:-1px;left:-6px;width: 5px;height:100%;border:1px solid #dcdfe6;border-right:0px;border-radius:5px 0px 0px 5px;background-color: #409eff;"></div>
              <el-descriptions id="descriptionsItem" style="paddingLeft: 13px" direction="vertical" :column=2>
                <template #title>
                  <el-icon style="padding-top:2px; vertical-align: text-top; font-size:16px;"><el-icon-Opportunity /></el-icon>
                  操作指引
                </template>
                <el-descriptions-item label="配置说明:">考试标题、考试时间段为必填配置，按要求填写后即出现后续配置。右上角配置进度仅指示第一个配置框，选配内容按需配置，点击右下角'应用配置'按钮后将校验配置，完成后将点亮所有进度并开始考场渲染。后续可通过右侧导航按钮返回本全局配置列表，通过配置选配功能对考场进行全局配置。</el-descriptions-item>
                <el-descriptions-item label="缓存说明:">本工具自带缓存功能，加载本页时将自动检查是否有上一次填写记录并自动应用，减少重复读取与填写。由于读取文件后将产生大量数据，受浏览器本地存储大小的限制，故没有设置缓存多次填写记录的功能，仅能保存最近一次填写记录。</el-descriptions-item>
              </el-descriptions>
            </el-card>
            <el-form  label-width="85px" style="width: 100%;  padding: 15px 15px; box-sizing: border-box;">
              <el-form-item label="考试标题">
                <el-input v-model="formData.examTitle" :placeholder="examTitlePlaceholder" @blur = "handleDatePickerChange" @focus="handleExamTitleFocus"/>
              </el-form-item>
              <el-form-item label="考试时间">
                <div>
                  <el-date-picker
                      v-model="formData.examDate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="起始日期"
                      end-placeholder="结束日期"
                      @change = "handleDatePickerChange"
                      style="width: 394px"
                  />
                </div>
              </el-form-item>
              <el-form-item v-if="dataUploadDisplay" label="考生导入">
                <el-upload
                    ref="uploadRef"
                    class="upload-demo"
                    :auto-upload="false"
                    accept=".xlsx"
                    @change="handleFileUpload"
                >
                  <template #trigger>
                      <el-button color="#409eff" plain @click="clearFileList" style="width: 130px">数据导入</el-button>
                      <el-button v-if="dataPreviewBtn" @click.stop="handleDataDialog" style="width: 130px">数据预览</el-button>
                      <el-button style="width: 130px;" @click.native.stop="downloadDemo">数据样例</el-button>
                  </template>
                </el-upload>


              </el-form-item>
              <el-form-item v-if="subTypeCheckboxDisplay" :label="IsSubjectStreamed? '编排选科': '编排列表'">
                <div class="mt-4">
                  <el-switch
                      class="subjectSwitch"
                      v-model="IsSubjectStreamed"
                      active-text="已分科"
                      inactive-text="未分科"
                  />
                  <el-checkbox-group v-model="subTypeCheckbox">
                    <el-checkbox v-for="(sheet, index) in excelSheets" :label="sheet.name" border style="margin-right:10px; margin-bottom: 10px"/>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item v-if="subTypeDataCheckedDisplay" label="试室分配">
                <el-alert :title="subTypeAlert" type="warning" show-icon :closable="false" style="margin-bottom: 15px"/>
                <el-card shadow="hover" v-for="(title, index) in subTypeDataChecked" :key = index :name="index" style="display: block; width: 100%;  margin-bottom: 15px">
                  <template #header>
                    <div class="card-header">
                      <span>{{title.type + ' / 总计  ' + title.len + '条数据'}}</span>
                    </div>
                  </template>
                  <div style="padding: 15px 20px 15px 20px">
                    <div v-if="title.remainingNumText" style="color: #c92c2c;" >还剩{{title.remainingNum}}人未分配试室</div>
                    <div v-if="!title.remainingNumText" style="color: #486a94;" >本类型试室分配完成 <el-icon style="vertical-align: text-top"><SuccessFilled /></el-icon></div>
                    <el-tag
                        :key="tag"
                        v-for="tag in subTypeDataChecked[index].roomSetting"
                        closable
                        class="mx-1"
                        size="large"
                        :type="tag.tagType"
                        :disable-transitions="false"
                        @close="handleTagClose(tag.roomName,index)">
                      {{tag.roomName + ' （ ' + tag.len + '人 ） '}}
                    </el-tag>
                    <el-input
                        :ref="'saveTagInput'+index"
                        class="input-new-tag"
                        placeholder="人数"
                        type="number"
                        v-if="subTypeDataChecked[index].inputTagVisible"
                        v-model="subTypeDataChecked[index].tempRoomLen"
                        @keyup.enter.native="handleTagInputConfirm(index,'outInput', 'enter')"
                        @focus ="handleInputFocus(index, 'outInput')"
                        @blur="handleTagInputConfirm(index,'outInput','lose')"
                    >

                      <template #prepend style="background-color: #ffffff">
                        <el-input
                            :ref="'saveTagPreInput'+index"
                            style="width: 90px;" class="elInputInner"
                            :placeholder="'第'+(index+1)+'试室'"
                            v-model="subTypeDataChecked[index].tempRoomName"
                            @focus ="handleInputFocus(index,'innerInput')"
                            @blur="handleTagInputConfirm(index,'innerInput')"
                        >
                        </el-input>
                      </template>
                    </el-input>
                    <el-button v-else class="button-new-tag" :disabled="subTypeDataChecked[index].addRoomBtnDisabled" size="small" @click.stop="showTagInput(index)">+ 新增试室</el-button>
                  </div>
                </el-card>
                <el-card shadow="never" style="display: block; width: 100%;">
                  <template #header>
                    <div class="card-header">
                      <span>混合选科考场（按需配置）</span>
                      <el-button type="primary" plain style="margin-left: 10px;" @click="handleMixedSubExamRoom">+&nbsp&nbsp新增混合试室</el-button>
                    </div>
                  </template>
                  <div v-if="mixedSubExamRoom.length != 0" style="padding: 15px 20px 0px 20px" class="mixRoomMulSelect">
                    <div style="display: inline-block; marginRight: 15px; marginBottom: 15px" v-for="(item,index) in mixedSubExamRoom">
                      <el-input
                          :key="index"
                          v-model="item.mixRoom.name"
                          placeholder="混合试室名称"
                          style="width: 360px;"
                      >
                        <template #append>
                          <el-select
                              v-model="item.mixRoom.value"
                              multiple
                              placeholder="配置混合考场"
                              style="width: 220px"
                              @change="handleMulSelect(item,index)"
                              @remove-tag="handleMulSelectDel"
                          >
                            <div v-for="(subType, index) in subTypeDataChecked" :key="index">
                              <el-option
                                  v-for="room in subType.roomSetting"
                                  :key="room.roomName"
                                  :label="room.roomName"
                                  :value="room.roomName"
                              />
                            </div>
                          </el-select>
                        </template>
                      </el-input>
                      <div style="display: inline-block; height: 32px"><el-tag type="info" style="height: 100%; margin: 0 0 0 5px; border-color: #dcdfe6" :key="'tag'+index" :ref="'tag'+index">人数：{{item.len}}</el-tag></div>
                      <el-button type="danger" :icon="Close" plain style="width: 34px; margin-left: 5px; box-sizing: border-box; font-weight: bolder" @click="handleMixRoomDelItem(index)"/>
                    </div>
                  </div>
                </el-card>
              </el-form-item>
            </el-form>
            <el-dialog
                class="forExamSettingDialog"
                style="height: 80vh;overflow-y: scroll;"
                v-model="dataPreviewDialog"
                width="80%"
                :show-close="false"
                align-center
            >
              <template #header="{ close }">
                <div style="display: flex;flex-direction: row;justify-content: space-between; ">
                  <h2>数据预览</h2>
                  <el-button style="margin-top: 16px"  type="danger" @click="close">
                    <el-icon><CircleCloseFilled /></el-icon>
                    关闭
                  </el-button>
                </div>
                <el-alert title="请检测表格内容是否被正确读取。数据表格尽量不要有合并单元格。" type="warning" show-icon style="padding-left: 10px" />
              </template>
                <el-tabs v-if="excelSheets.length" tab-position="left">
                  <el-tab-pane
                      v-for="(sheet, index) in excelSheets"
                      :key="index"
                      :label="sheet.name">
                    <div style="height: 100vh">
                      <el-auto-resizer>
                        <template #default="{ height, width }">
                          <el-table-v2
                              :columns=" sheet.columns"
                              :data="sheet.data"
                              :width="width"
                              :height="height"
                              fixed
                          />
                        </template>
                      </el-auto-resizer>
                    </div>
                  </el-tab-pane>
                </el-tabs>
            </el-dialog>
          </el-card>
          <el-card shadow="hover" style="padding-top: 8px; margin-top: 20px">
            <el-form label-width="85px" style="width: 100%;  padding: 15px 15px; box-sizing: border-box;">
              <el-form-item>
                <div style="color: var(--el-text-color-primary);font-size: 16px;font-weight: 700;margin-bottom: 15px">
                  <el-icon style="padding-top:1.5px; vertical-align: text-top; font-size:16px;"><el-icon-List /></el-icon>
                  考场概要全局配置 (选配)
                </div>
                <el-alert title="本配置为非必填配置，默认情况下将采用考场默认描述。在排座完成后可以在画布上单击拖动及修改宽度，双击可以单独配置修改文字。" type="info" style="padding-left: 10px" />
              </el-form-item>
              <el-form-item v-if="markCheckboxDisplay">
                <template #label>
                  <el-popover
                      placement="top-start"
                      title="标记特殊考生"
                      :width="260"
                      trigger="hover"
                      popper-class = "markPopperClass"
                      :popper-style = "{fontSize: '13px'}"
                      content="由于各类外语考试一般在同一时间段，为避免同场考试发不同卷子，本校对于特殊选科（如：日语、俄语）考生会专门设立考场。标记其他外语专业考生可让监考教师更加方便核对本场考试考生人数。"
                  >
                    <template #reference>
                      <el-icon style="color: #3192f8;padding: 9px 3px 0px 0px"><QuestionFilled /></el-icon>
                    </template>
                  </el-popover>
                  特殊标记
                </template>
                <div class="mt-4">
                  <el-checkbox-group v-model="markCheckbox">
                    <el-checkbox v-for="(sheet, index) in excelSheets" :label="sheet.name" border style="margin-right:10px; margin-bottom: 10px"/>
                  </el-checkbox-group>
                </div>
              </el-form-item>
              <el-form-item label="前门位置" >
                <el-radio-group v-model="doorOffsetStart">
                  <el-radio-button label="TopLeft" border>
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-TopLeft/>
                      </el-icon>
                      左上
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="TopRight" border>
                    <el-space :size="0">
                      右上
                      <el-icon style="font-size: 15px">
                        <el-icon-TopRight/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="BottomLeft" border>
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-BottomLeft/>
                      </el-icon>
                      左下
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="BottomRight" border>
                    <el-space :size="0">
                      右下
                      <el-icon style="font-size: 15px">
                        <el-icon-BottomRight/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="考场备注">
                <el-input
                    v-model="formData.examRoomTextArea"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder="请编辑考场备注"
                />
              </el-form-item>
            </el-form>
          </el-card>
          <el-card shadow="hover" class="settingCard">
            <el-form :inline="true" label-width="85px" style="width: 100%; padding-top: 8px;  box-sizing: border-box;">
              <el-form-item style="display:block; margin-left: 85px; margin-right: 8px">
                <div style="color: var(--el-text-color-primary);font-size: 16px;font-weight: 700;margin-bottom: 15px">
                  <el-icon style="padding-top:1.5px; vertical-align: text-top; font-size:16px;"><el-icon-Tools /></el-icon>
                  座位全局配置 (选配)
                </div>
                <el-alert title="本配置为非必填配置，默认情况下将采用考场默认配置，本配置将应用全局。" type="info" style="padding-left: 10px"/>
              </el-form-item>
              <el-form-item label="纸张方向">
                <el-radio-group v-model="direction">
                  <el-radio-button label="landscape" border >
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-Sort style="transform: rotate(-90deg)"/>
                      </el-icon>
                      横向
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="portrait" border >
                    <el-space :size="0">
                      纵向
                      <el-icon style="font-size: 15px">
                        <el-icon-Sort/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="列数">
                <el-input-number v-model="cols" :min="1" id="cols"></el-input-number>
              </el-form-item>
              <el-form-item label="座位宽度">
                  <el-input-number v-model="blockWidth" :min="80" :max="500" id="blockWidth"></el-input-number>
              </el-form-item>
              <el-form-item label="座位高度">
                  <el-input-number v-model="blockHeight" :min="55" :max="200" id="blockHeight"></el-input-number>
              </el-form-item>
              <el-form-item label="标题大小">
                <el-input-number v-model="titleFontSize" :min="1" :max="100" ></el-input-number>
              </el-form-item>
              <el-form-item label="备注大小">
                <el-input-number v-model="examRoomTextAreaSize" :min="1" :max="100" ></el-input-number>
              </el-form-item>
              <el-form-item label="排座起点" >
                <el-radio-group v-model="seatOffsetStart">
                  <el-radio-button label="TopLeft" border>
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-TopLeft/>
                      </el-icon>
                      左上
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="TopRight" border>
                    <el-space :size="0">
                      右上
                      <el-icon style="font-size: 15px">
                        <el-icon-TopRight/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="BottomLeft" border>
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-BottomLeft/>
                      </el-icon>
                      左下
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="BottomRight" border>
                    <el-space :size="0">
                      右下
                      <el-icon style="font-size: 15px">
                        <el-icon-BottomRight/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item>
                <template #label>
                  <el-popover
                      placement="top-start"
                      title="排列方向 【起点于左下时可用】"
                      :width="338"
                      trigger="hover"
                      popper-class = "markPopperClass"
                      :popper-style = "{fontSize: '13px'}"
                      content="考场学生默认纵向蛇形排列，因此有时会出现最后一列前排空位现象。开优先横向填充后，系统会将最后一排学生左右交替填补到前排空位上。但由于本系统特殊但网格机制，有时会出现两列空列现象，此时横向优先的功能效果会不理想，各位老师需手动拖动调整。"
                  >
                    <template #reference>
                      <el-icon style="color: #3192f8;padding: 9px 3px 0px 0px"><QuestionFilled /></el-icon>
                    </template>
                  </el-popover>
                  排列方向
                </template>

                <el-radio-group v-model="fillDirection" :disabled="seatOffsetStart === 'BottomLeft'?false:true">
                  <el-radio-button label= "1" border >
                    <el-space :size="0">
                      <el-icon style="font-size: 15px;">
                        <el-icon-Sort style="transform: rotate(-90deg)"/>
                      </el-icon>
                      横向
                    </el-space>
                  </el-radio-button>
                  <el-radio-button label="0" border >
                    <el-space :size="0">
                      纵向
                      <el-icon style="font-size: 15px">
                        <el-icon-Sort/>
                      </el-icon>
                    </el-space>
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-card>
        </template>
        <template #footer>
          <div style="flex: auto">
            <el-dialog
                v-model="resetDialogVisible"
                title="警告"
                width="500"
                style="text-align: left"
                align-center
            >
              <span>重置后将清除之前的配置缓存并且无法恢复！</span>
              <template #footer>
                <div class="dialog-footer">
                  <el-button @click="resetDialogVisible = false">取消</el-button>
                  <el-button type="danger" @click="resetClick">
                    重置
                  </el-button>
                </div>
              </template>
            </el-dialog>
            <el-button type="primary" plain v-if="showInstallButton" @click="installPWA">安装此应用</el-button>
            <el-button type="danger" @click="resetDialog">重置</el-button>
            <el-button v-if="closeDrawer" @click="cancelClick">取消</el-button>
            <el-button type="primary" @click="confirmClick">应用配置</el-button>
          </div>
        </template>
      </el-drawer>
      <el-main ref='elMain' v-show="showDom" style="padding:0px;"  class="content">
        <seatPosition
            v-if="showRoom"
            v-for = "(item, index) in roomData"
            :id= "'section'+ (index+1)"
            :key = index
            :odata = item
            :roomIndex = index
            :ref="'section'+ (index+1)"
        />
      </el-main>
      <el-aside v-show="showDom" style="position:relative; width:160px; ">
        <div :style="{position:'fixed', top: '0px', right:tocXPosition, width: '160px', height:'100vh'}">
          <ul ref="tocUl" class="toc">
            <div style="width:120px; background-color:#efefef; margin-bottom: 20px; padding: 11px 0px 11px 30px; border-radius: 5px; box-sizing: border-box;">
              <label style="font-weight: bolder; color:#6c6e72">考场一览</label>
            </div>
            <li v-for="section in sections" :key="section.id" :class="{ active: currentSection === section.id }">
              <a @click.prevent="scrollTo(section.id)">{{ section.title }}</a>
            </li>
            <el-divider content-position="center" style="width: 120px;">
              <el-icon size="16"><LocationInformation/></el-icon>
            </el-divider>
            <el-button color="#409eff" plain :icon="ArrowLeft" style="width: 120px; margin-bottom: 15px" @click="drawerShow = !drawerShow">全局配置</el-button>
            <el-button type="primary" plain :icon="DocumentChecked" style="width: 120px; margin: 0px 0px 15px 0px" @click="dataSave">数据导出</el-button>
            <el-button type="primary" :icon="Download" style="width: 120px; margin: 0px 0px 15px 0px" @click="pdfSave">全局保存</el-button>
          </ul>
          <div v-show="showRoom" ref="tocMarker" class="toc-marker"></div>
          <el-backtop :bottom="45" :style="{ right:'unset', marginLeft:'20px', borderRadius:'5px'}"/>
        </div>
      </el-aside>
    </el-container>
  </div>
</template>

<script>
import seatPosition from './components/seatPosition.vue'
import {
  ArrowLeft, Download, DocumentChecked,LocationInformation, CircleCloseFilled, Tools, SuccessFilled, TopLeft,TopRight,BottomLeft,BottomRight,Sort,List,Opportunity,QuestionFilled,Close
} from '@element-plus/icons-vue'
import * as XLSX from 'xlsx';
import {markRaw} from "vue";
import {ElLoading, ElMessage} from "element-plus";
import { jsPDF } from "jspdf";

export default {
  name: 'App',
  components: {
    seatPosition,
    LocationInformation,
    CircleCloseFilled,
    SuccessFilled,
    QuestionFilled,
    Tools,
    'el-icon-TopLeft': TopLeft,
    'el-icon-TopRight': TopRight,
    'el-icon-BottomLeft': BottomLeft,
    'el-icon-BottomRight': BottomRight,
    'el-icon-Sort':Sort,
    'el-icon-List':List,
    'el-icon-Tools':Tools,
    'el-icon-Opportunity':Opportunity,
  },
  data(){
    return{
      deferredPrompt: null,
      showInstallButton: false,
      tocXPosition:'0px',
      showDom: false,
      showRoom : false,
      closeDrawer: false,
      drawerShow: true,
      drawerWidth: 0,
      currentSection: 'section1',
      examTitlePlaceholder:'例：2024年1月佛山市南执高级中学高三级市统考',
      stepsActive:0,
      ArrowLeft:markRaw(ArrowLeft),
      Download:markRaw(Download),
      DocumentChecked:markRaw(DocumentChecked),
      excelSheets:[],
      sections: [],
      tableColumns: [],
      dataPreviewBtn: false,
      dataPreviewDialog: false,
      formData:{
        examTitle:'',
        examDate:[],
        examRoomTextArea:'',
      },
      titleFontSize: 39,
      examRoomTextAreaSize: 17,
      dataUploadDisplay:false,
      subTypeAlert:'分配试室时，系统会按试室数量填入默认试室名，建议按本校情况修改试室名。如有删除试室操作，容易导致试室名重复，请注意修改试室名。当需要配置混合考场时，可以将准备用来混合的试室名字改为\'选科+特殊标记\'的形式，方便后续配置。',
      subTypeCheckboxDisplay:false,
      subTypeCheckbox:[],
      markCheckbox:[],
      markCheckboxDisplay: false,
      subTypeDataCheckedDisplay:false,
      subTypeDataChecked:[],
      activeCollapseNames:[],
      dynamicTags: [],
      inputTagVisible: false,
      inputTagValue: '',
      focusState: {
        "outInput": false,
        "innerInput": false,
      },
      enterPress: false,
      doorOffsetStart:'BottomLeft',
      direction:'landscape',
      fillDirection: 1,
      cols:6,
      seatOffsetStart:'BottomLeft',
      blockWidth:168,
      blockHeight:75,
      roomDataOriginal:[],
      roomData:[],
      mixedSubExamRoom:[],
      mixLen:0,
      IsSubjectStreamed: true,
      Close:markRaw(Close),
      reset: false,
      resetDialogVisible: false,
    }
  },

  watch:{
    subTypeCheckbox(newValue, oldValue){
      if (this.subTypeCheckbox.length>0){
        this.subTypeDataCheckedDisplay = true
      }else{
        this.subTypeDataCheckedDisplay = false
      }
      if(newValue.length > oldValue.length){
        newValue.forEach(
            (item)=> {
              let existence = 0
              this.subTypeDataChecked.forEach((e)=>{if(e.type == item)existence = 1})
              if(existence == 0){
                const len = this.excelSheets.filter((e) => e.name == item)[0].data.length
                this.subTypeDataChecked.push({"type":item, "len":len, "remainingNum":len, "addRoomBtnDisabled":false, "remainingNumText":true, "tempRoomName":'', "tempRoomLen":'', "inputTagVisible":false , "roomSetting":[]})
              }
            }
        )
      }else{
        const diff = oldValue.filter(item=> !newValue.includes(item))
        const oIndex = this.subTypeDataChecked.findIndex(item => item.type === diff[0])
        if (oIndex !== -1) {
          setTimeout(()=>{
            this.subTypeDataChecked.splice(oIndex, 1);
          },10)

        }
      }
      this.activeCollapseNames = []
      for(let i in newValue){
        this.activeCollapseNames.push(parseInt(i))
      }
    },
  },

  created() {
    let storeData = localStorage.getItem('topData')
    if (storeData){
      Object.assign(this.$data,JSON.parse(storeData))
      this.ArrowLeft = markRaw(ArrowLeft)
      this.Download = markRaw(Download)
      this.DocumentChecked = markRaw(DocumentChecked)
      this.Close = markRaw(Close)
    }
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },

  mounted() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallButton = true
    });
    this.calculateDrawWidth()
    const debouncedResize = this.debounce(this.calculateDrawWidth, 200);
    window.addEventListener('resize', debouncedResize);
    const tocMarker = this.$refs.tocMarker
    const tocUl = this.$refs.tocUl
    tocMarker.style.top =  tocUl.offsetTop + 85 + 'px'

    window.addEventListener('beforeunload',this.handleBeforeUnload)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.debouncedResize);
    window.removeEventListener('beforeunload',this.handleBeforeUnload)
  },
  methods:{
    calculateDrawWidth(){
      const inW = window.innerWidth
      if(inW >= 1500){
        this.drawerWidth = inW - (inW - 1500)/2 - 165
        this.tocXPosition = 'unset'
      }else {
        this.drawerWidth = inW
        this.tocXPosition = '0px'
      }


    },
    onScroll() {
      // 定义一个获取当前区域的方法
      const sections = this.sections.map(section => section.id);
      const scrollTop = window.scrollY;
      const sectionOffsets = sections.map(id => document.getElementById(id).offsetTop);

      // 判断当前滚动位置所在的区域
      let currentSectionIndex = sectionOffsets.findIndex((offset, index) => {
        const nextOffset = sectionOffsets[index + 1] || Infinity;
        return scrollTop >= offset && scrollTop < nextOffset;
      });
      currentSectionIndex = currentSectionIndex + 1
      if (currentSectionIndex >= 0) {
        this.currentSection = sections[currentSectionIndex];
      }
      this.$nextTick(() => {
        if(this.$refs.tocMarker){
          const tocMarker = this.$refs.tocMarker
          const tocUl = this.$refs.tocUl
          tocMarker.style.top = currentSectionIndex * 30 + tocUl.offsetTop + 70 + 'px'
        }
      })

    },
    scrollTo(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
        const offset = 20;
        window.scrollTo({
          top: elementTop - offset,
          behavior: 'smooth'
        });
        this.currentSection = sectionId;
      }
    },
    debounce(func, wait) {
      let timeout;
      return function(...args) {
        const later = () => {
          clearTimeout(timeout);
          func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    handleDatePickerChange(){
      try{
        if(this.formData.examTitle.length > 0 && this.formData.examDate.length > 0){
          if(this.stepsActive < 1)this.stepsActive = 1
          this.dataUploadDisplay = true
        }
      }catch (err){
        console.log(err)
      }
    },
    handleExamTitleFocus(){
      this.formData["examTitle"] = this.examTitlePlaceholder.slice(2)
    },
    clearFileList(){
      this.$refs.uploadRef.clearFiles()
    },
    handleFileUpload(event) {
      const file = event.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        this.excelSheets = []; // 清空现有数据

        const headerMap = {
          '考生号': 'oId',
          '姓名': 'name',
        };

        workbook.SheetNames.forEach(sheetName => {
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const firstNonEmptyRowIndex = jsonData.findIndex(row => row.some(cell => cell !== null && cell !== ''));

          // 修改 columns 结构以匹配虚拟化表格要求
          const columns = jsonData[firstNonEmptyRowIndex].map((header, index) => ({
            key: header,
            dataKey: headerMap[header] || header,
            title: header,
            width: 200,
          }));

          const data = jsonData.slice(firstNonEmptyRowIndex + 1).map((row, rowIndex) => {
            return columns.reduce((rowData, column, columnIndex) => {
              rowData[column.dataKey] = row[columnIndex] || '';
              return rowData;
            }, { id: `row-${rowIndex}` });
          });

          this.excelSheets.push({
            name: sheetName,
            columns: columns,
            data: data
          });
        });
      };
      reader.readAsArrayBuffer(file);
      this.dataPreviewDialog = true
      this.dataPreviewBtn = true
      this.subTypeCheckboxDisplay = true
      this.markCheckboxDisplay = true
      if(this.formData.examDate.length > 0 && this.formData.examTitle.length > 0){
        if(this.stepsActive < 2)this.stepsActive  = 2
      }

      this.subTypeCheckbox = []
      this.subTypeDataChecked = []
    },
    handleDataDialog(){
        this.dataPreviewDialog = true
    },
    handleTagClose(tag,index) {
      const oIndex = this.subTypeDataChecked[index].roomSetting.findIndex(room => room.roomName === tag);

      if (oIndex !== -1) {
        this.subTypeDataChecked[index].remainingNum = parseInt(this.subTypeDataChecked[index].remainingNum) +  parseInt(this.subTypeDataChecked[index].roomSetting[oIndex].len)
        this.subTypeDataChecked[index].addRoomBtnDisabled = false
        this.subTypeDataChecked[index].roomSetting.splice(oIndex, 1);
        this.subTypeDataChecked[index].remainingNumText = true
      }

      //当试室tag发生变化时清空混合考场列表
      this.mixedSubExamRoom = []
    },
    showTagInput(index) {
      this.subTypeDataChecked[index].inputTagVisible = true;
      this.$nextTick(_ => {
        this.$refs['saveTagPreInput'+index][0].focus()
      });

      if (this.subTypeCheckbox.length > 0) {
        if(this.stepsActive < 3)this.stepsActive = 3
      }
    },
    handleTagInputConfirm(index,Str,Str2) {
      this.focusState[Str] = false
      setTimeout(()=>{
        if(this.focusState.outInput == false && this.focusState.innerInput == false){
          let inputTagValue = this.subTypeDataChecked[index].tempRoomName;
          let inputTagLen = this.subTypeDataChecked[index].tempRoomLen;
          let existence = 0 //用于检测试室名是否已经存在的标记，当值为1时即阻止后续动作发生
          this.subTypeDataChecked.forEach((item)=>{
            item.roomSetting.forEach((inItem)=>{
              if(inItem.roomName == inputTagValue){
                existence = 1
                ElMessage({
                  showClose: true,
                  message: '试室名重复，请修改试室名',
                  type: 'error',
                  duration: 5000,
                })
              }
            })
          })

          if (inputTagLen.length != 0 && inputTagLen != 0) {
            let tempRemainingNum = this.subTypeDataChecked[index].remainingNum - inputTagLen
            if (tempRemainingNum >= 0) {
              if(!existence){
                this.subTypeDataChecked[index].remainingNum = tempRemainingNum
                if(this.subTypeDataChecked[index].remainingNum == 0){
                  this.subTypeDataChecked[index].addRoomBtnDisabled = true
                  this.subTypeDataChecked[index].remainingNumText = false
                }
                this.subTypeDataChecked[index].roomSetting.push({"roomName": inputTagValue, "len": inputTagLen,"tagType":''})
                this.subTypeDataChecked[index].inputTagVisible = false;
                this.subTypeDataChecked[index].tempRoomName = '';
                this.subTypeDataChecked[index].tempRoomLen = '';
              }
            }else{
              ElMessage({
                showClose: true,
                message: '分配人数超出计划人数',
                type: 'error',
                duration: 5000,
              })
            }
          } else {
            if(this.enterPress == false) {
              ElMessage({
                showClose: true,
                message: '请填写参与本试室考试人数',
                type: 'error',
                duration: 5000,
              })
            }
          }

          if(Str2 == 'enter') {
            this.enterPress = true
          }else{
            this.enterPress = false
          }

        }
      },0)

    },
    handleInputFocus(index,Str){
      let roomIndex = 0
      this.subTypeDataChecked.forEach((item)=>{
        roomIndex += item.roomSetting.length
      })
      if(this.subTypeDataChecked[index].tempRoomName.length == 0)this.subTypeDataChecked[index].tempRoomName = "第" + (roomIndex+1) + "试室"
      this.focusState[Str] = true
    },
    shuffle(stuData) {
      for (let i = stuData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [stuData[i], stuData[j]] = [stuData[j], stuData[i]];
      }
      return stuData
    },
    handleMulSelect(item, index){

      const preOp = item.mixRoom?.previousOptions || []
      const unselected = preOp.filter(x => !item.mixRoom.value.includes(x));
      if (unselected.length > 0) {
        this.subTypeDataChecked.forEach(dataCheckedItem=>{
          dataCheckedItem.roomSetting.forEach(oItem=>{
            if(oItem.roomName == unselected[0]) {
              oItem.tagType = ''
            }
          })
        })
      }

      item.mixRoom.previousOptions = [...item.mixRoom.value];

      let olen = 0
      item.mixRoom.value.forEach(outItem=>{
        for(let dataCheckedItem of this.subTypeDataChecked){
          for(let oItem of dataCheckedItem.roomSetting){
            if(oItem.roomName == outItem) {
              oItem.tagType = 'info'
              olen += parseInt(oItem.len)
              break
            }
          }
        }
      })

      item.len = olen
    },
    handleMulSelectDel(tagValue){
      let flag = 0
      for(let item of this.subTypeDataChecked){
        for(let oItem of item.roomSetting){
          if(oItem.roomName == tagValue) {
            oItem.tagType = ''
            flag = 1
            break
          }
        }
        if(flag == 1)break
      }
    },
    handleMixedSubExamRoom(){
      this.mixedSubExamRoom.push({
        "roomName": "",
        "len": 0,
        "type": "",
        "stuData": [],
        "examTitle": "",
        "examDate": [],
        "roomId": "",
        "defaultRoomNoticeText": "",
        "mark": {},
        "mixRoom":{
          name:'',
          value:'',
        }
      })
    },
    handleMixRoomDelItem(index){
      this.mixedSubExamRoom[index].mixRoom.value.forEach(outItem=>{
        let flag = 0
        for(let item of this.subTypeDataChecked){
          for(let oItem of item.roomSetting){
            if(oItem.roomName == outItem) {
              oItem.tagType = ''
              flag = 1
              break
            }
          }
          if(flag == 1)break
        }
      })

      this.mixedSubExamRoom.splice(index, 1);
    },
    MixRoom(toBeMix, mixR, oIndex, aim) {
      let mergedData = [];
      let typeStr = ''
      let typeNum = {}
      let typeStrSet = new Set()
      toBeMix.forEach((item, index) => {
        for (let obj of aim) {
          if (obj.roomName === item) {
            let tempStuData = JSON.parse(JSON.stringify(obj.stuData))
            tempStuData.forEach(item=>{
              item.mark = item.mark || []
              item.mark.push(obj.type)
              mergedData.push(item)
            })
            typeStrSet.add(obj.type)

            for (let key in obj.mark) {
              if (obj.mark.hasOwnProperty(key)) {
                if (typeNum.hasOwnProperty(key)) {
                  typeNum[key] += obj.mark[key];
                } else {
                  typeNum[key] = obj.mark[key];
                }
              }
            }
            if(this.IsSubjectStreamed)typeNum[obj.type] = tempStuData.length
            break
          }
        };
      });

      typeStr = Array.from(typeStrSet).join(' | ')

      mixR.roomName = mixR.mixRoom.name
      mixR.type =  `mix`
      mixR.stuData = mergedData
      mixR.examTitle = aim[0].examTitle
      mixR.examDate = aim[0].examDate
      mixR.roomId = `mix${oIndex + 1}`
      if(this.IsSubjectStreamed){
        mixR.defaultRoomNoticeText = mixR.mixRoom.name + '    选科：' + typeStr + '   人数：' + mergedData.length
      }else{
        mixR.defaultRoomNoticeText = mixR.mixRoom.name + '   人数：' + mergedData.length
      }
      mixR.mark = typeNum
      switch (this.doorOffsetStart){
        case 'TopLeft':
          mixR.doorPO = '试室前门 位于左上方'
          break
        case 'TopRight':
          mixR.doorPO = '试室前门 位于左上方'
          break
        case 'BottomLeft':
          mixR.doorPO = '试室前门 位于左下方'
          break
        case 'BottomRight':
          mixR.doorPO = '试室前门 位于右下方'
          break
      }
      mixR.examRoomTextArea = this.formData.examRoomTextArea
      this.roomDataOriginal.push(mixR)
    },
    async downloadDemo() {

      const fileUrl = process.env.BASE_URL + 'files/2021级全年级学生名单demo.xlsx';
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', '2021级全年级学生名单demo.xlsx');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    },
    confirmClick(){
      let remainingNumText = 0

      this.subTypeDataChecked.forEach((item)=>{
        if (item.remainingNumText == true)remainingNumText = 1
      })

      let flag = 0
      if(this.mixedSubExamRoom.length > 0){
        this.mixedSubExamRoom.forEach(item=>{
          if(item.mixRoom.name == '')flag = 1
          if(item.mixRoom.value.length == 0)flag = 1
        })
      }

      if(flag == 1){
        ElMessage({
          message: '混合考场配置未完成',
          type: 'error',
          duration: 1000,
        })
        return
      }

      if(remainingNumText == 0 && this.subTypeDataChecked.length != 0 && this.stepsActive >= 3 && this.formData.examDate?.length != null){
        if(this.stepsActive < 4)this.stepsActive = 4
        const loadingInstance = ElLoading.service({
          fullscreen: true ,
          lock: true,
          background: "rgba(122, 122, 122, 0.3)",
          text: '考场生成中...',
        })

        setTimeout(()=>{
          this.showRoom = false
          let tempStuData = []
          this.roomDataOriginal = []
          let excelSheets = JSON.parse(JSON.stringify(this.excelSheets))

          excelSheets.forEach(item=>{
            tempStuData.push({name:item.name,data:this.shuffle(item.data)})
          })
          let toIndex = 1

          try{
            this.subTypeDataChecked.forEach(item=>{

              this.markCheckbox.forEach(markCheckboxItem=>{
                let tempData = tempStuData.find(oItem => oItem.name == markCheckboxItem)
                let set = new Set();
                tempData['data'].forEach(tpDataItem => set.add(tpDataItem.name));

                const currentType = item.type
                let currentTempStuData = tempStuData.find(oItem => oItem.name == currentType)
                currentTempStuData['data'].forEach((inItem,index) => {
                  if(set.has(inItem.name)){
                    inItem.mark =inItem.mark || []
                    inItem.mark.push(markCheckboxItem)
                    inItem.filter = 1
                  }
                })
              })

              let sliceIndexB = 0
              item.roomSetting.forEach(innerItem=>{
                innerItem.type = item.type
                tempStuData.forEach(tempStuDataItem=>{
                  if(tempStuDataItem.name == item.type){
                    innerItem.stuData = tempStuDataItem.data.slice(sliceIndexB, sliceIndexB + parseInt(innerItem.len))
                    sliceIndexB += (parseInt(innerItem.len))
                  }
                })
                innerItem.examTitle = this.formData.examTitle
                innerItem.examDate = this.formData.examDate
                innerItem.roomId = innerItem.roomName
                if(this.IsSubjectStreamed){
                  innerItem.defaultRoomNoticeText = innerItem.roomName + '     选科: ' + item.type + '     人数: ' + innerItem.len
                }else{
                  innerItem.defaultRoomNoticeText = innerItem.roomName + '     人数: ' + innerItem.len
                }
                switch (this.doorOffsetStart){
                  case 'TopLeft':
                    innerItem.doorPO = '试室前门 位于左上方'
                    break
                  case 'TopRight':
                    innerItem.doorPO = '试室前门 位于左上方'
                    break
                  case 'BottomLeft':
                    innerItem.doorPO = '试室前门 位于左下方'
                    break
                  case 'BottomRight':
                    innerItem.doorPO = '试室前门 位于右下方'
                    break
                }
                innerItem.examRoomTextArea = this.formData.examRoomTextArea

                const count = innerItem['stuData'].reduce((acc, obj) => {
                  if (obj.mark) {
                    obj.mark.forEach(element => {
                      if (!acc[element]) {
                        acc[element] = 0;
                      }
                      acc[element] += 1;
                    });
                  }
                  return acc;
                }, {});

                innerItem.mark = count

                this.roomDataOriginal.push(innerItem)
                toIndex += 1
              })
            })

            this.mixedSubExamRoom.forEach((item,index)=>{
              this.MixRoom(item.mixRoom.value, item, index, this.roomDataOriginal)
            })

            this.roomData = []
            this.sections = []
            this.roomDataOriginal.forEach(item=>{
              if(item.tagType != "info"){
                this.roomData.push(item)
              }
            })

            this.roomData.forEach((item,index)=>{
              const strResult = Object.entries(item.mark).map(([subject, count]) => {
                return `${subject}考生${count}人`;
              }).join('，');
              let tempText = item.examRoomTextArea

              item.examRoomTextArea = '考场概述：本试室共' + item.len + '名考生，其中' + strResult +'。特殊考生已用灰色标出。'
              if(tempText.length > 0 ){
                item.examRoomTextArea += "\n考场备注：" + tempText
              }
              this.sections.push({'id': 'section'+(index + 1),'title': item.roomName})
              item.direction = this.direction
              item.fillDirection = this.fillDirection
              item.cols = this.cols
              item.blockWidth = this.blockWidth
              item.blockHeight = this.blockHeight
              item.seatOffsetStart = this.seatOffsetStart
              item.titleFontSize = this.titleFontSize
              item.examRoomTextAreaSize = this.examRoomTextAreaSize
            })
          }catch (error){
            console.log(error)
            ElMessage({
              message: '试室生成出错',
              type: 'error',
              duration: 5000,
            })
            loadingInstance.close();
          }finally{
            this.$nextTick(() => {
              this.showRoom = true
              loadingInstance.close()
              this.closeDrawer = true
              this.drawerShow = false
            })
          }
        },100)
      }else{
        ElMessage({
          message: '必要配置未完成',
          type: 'error',
          duration: 1000,
        })
      }

    },
    cancelClick(){
      this.drawerShow = false
    },
    resetDialog(){
      this.resetDialogVisible = true
    },
    resetClick(){
      localStorage.clear()
      this.reset = true
      location.reload()
    },
    handleBeforeUnload(){
      if (!this.reset) {
        localStorage.setItem('topData', JSON.stringify(this.$data))
      }else {
        this.reset = false
      };
    },
    dataSave(){
      let resultData = []
      this.roomData.forEach((item,index)=>{
        let tempData = this.$refs['section'+(index+1)][0].grid.flat().filter(item => item.name.trim() !== '')
        let originData = this.$refs['section'+(index+1)][0].roomData[0].stuData

        originData.forEach(bObj => {
          let aIndex = tempData.findIndex(aObj => aObj.name === bObj.name);
          if (aIndex !== -1) {
            tempData[aIndex] = {...tempData[aIndex], stuNum: bObj['学号']};
          }
        });

        resultData = [...resultData,...tempData]

      })

      function groupBy(array, key) {
        return array.reduce((result, currentValue) => {
          (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
          return result;
        }, {});
      }

      const groupedData = groupBy(resultData, 'roomId');

      Object.keys(groupedData).forEach(roomId => {
        groupedData[roomId].sort((a, b) => a.seatId - b.seatId);
      });

      const resultDataRoomSort = Object.values(groupedData).flat()

      resultData.sort((a, b) => parseInt(a.stuNum, 10) - parseInt(b.stuNum, 10));

      const headers = ['姓名', '学号', '试室号','座位号','考号'];
      const columnMap = {
        '姓名': 'name',
        '学号': 'stuNum',
        '试室号': 'roomId',
        '座位号': 'seatId',
        '考号': 'oId',
      };


      function dataOutput(resultData,sheetName){
        // 过滤和映射数据以匹配新的列名
        const processedData = resultData.map(item => {
          const newData = {};
          headers.forEach(header => {
            const key = columnMap[header];
            if(header == '考号'){
              newData[header] = item[key].split("：")[1];
            }else{
              newData[header] = item[key];
            }
          });
          return newData;
        });

        // 创建一个工作表
        const worksheet = XLSX.utils.json_to_sheet(processedData);
        XLSX.utils.book_append_sheet(wb, worksheet, sheetName);

      };

      const wb = XLSX.utils.book_new();

      dataOutput(resultDataRoomSort,'按试室序列')
      dataOutput(resultData,'按学号序列')

      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      try {
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.formData.examTitle+'.xlsx');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href); // 释放 URL 对象
        document.body.removeChild(link); // 清理创建的 a元素
      } catch (e) {
        console.error(e);
      }

    },
    pdfSave(){
      const loadingInstance = ElLoading.service({
        fullscreen: true ,
        lock: true,
        text: '正在生成 PDF...',
      })

      setTimeout(() => {

        const pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'mm',
          format: 'a4',
        });

        // 计算内容区域的宽度和高度
        const contentWidth = pdf.internal.pageSize.getWidth() ;
        const contentHeight = pdf.internal.pageSize.getHeight() ;

        const dataUrl = this.$refs['section1'][0].stage.toDataURL({pixelRatio: 3});
        if(this.$refs['section1'][0].direction=='landscape'){
          pdf.addImage(dataUrl, 'PNG', 0, 0, contentWidth, contentHeight);
        }else {
          pdf.addImage(dataUrl, 'PNG', 297, -87 , contentHeight, contentWidth,undefined,undefined,90);
        }
        let oPdf = ''
        this.roomData.forEach((item,index)=>{
          if(index != 0) {
            let dataUrl = this.$refs['section'+(index+1)][0].stage.toDataURL({pixelRatio: 3});
            oPdf = pdf.addPage('a4',"landscape")
            if(this.$refs['section'+(index+1)][0].direction=='landscape') {
              oPdf.addImage(dataUrl, 'PNG', 0, 0, contentWidth, contentHeight);
            }else{
              oPdf.addImage(dataUrl, 'PNG', 297, -87 , contentHeight, contentWidth,undefined,undefined,90);
            }
          };
        })


        try {
          oPdf.save(this.formData.examTitle + '.pdf');
        } catch (error) {
          console.error('PDF生成出错:', error);
          ElMessage({
            message: 'PDF生成出错',
            type: 'error',
            duration: 1000,
          })
          loadingInstance.close();
        } finally {
          loadingInstance.close();
          if (this.$refs.printButton && this.$refs.printButton.$el) {
            this.$refs.printButton.$el.blur();
          }
        }

      },100);
    },
    installPWA() {
      if (this.deferredPrompt) {
        // 显示安装提示
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null;
          this.showInstallButton = false; // 隐藏安装按钮
        });
      }
    },
  }
}
</script>

<style>
#app {
  font-family: Inter, 'Helvetica Neue', Helvetica, 'PingFang SC',
  'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
  min-width: 1500px;
  font-size: 14px;
}
#elDrawer header{
  margin-bottom: 0px;
}
#elDrawer .el-drawer__body{
  padding-top: 0px;
}
.active {
  color: #409eff;
}
#elSteps .el-step__icon {
  width: 22px;
  height: 22px;
}
#elSteps .el-step__line{
  top: 10px;
}
#elSteps .el-step__title{
  line-height: 30px;
}
#descriptionsItem td:first-child{
  padding-right: 32px;
}
#descriptionsItem td{
  text-align: justify;
}
.toc{
  max-height: 90vh;
  overflow-y: auto;
  border-radius: 3px;
  list-style: none;
  cursor: pointer;
  color: #909399;
  padding-top: 5px;
  padding-left: 20px;
  font-size: 15px;
  backdrop-filter: blur(15px);
}
.toc li{
  margin-bottom: 10px;
  font-size: 14px;
  width: 120px;
}
.toc .el-divider__text{
  padding: 2px 20px;
  border-radius: 3px;
}
.boxShadow{
  box-shadow: var(--el-box-shadow-lighter);
  margin-top: 20px;
  padding-top: 16px;
  padding-bottom: 30px;
}
.toc-marker{
  position: absolute;
  left: 9px;
  width: 4px;
  height: 16px;
  border-radius: 5px 5px 5px 5px;
  background-color: #409eff;
  transition: top 0.1s linear;

}
.el-drawer__header{
  margin-bottom: 5px;
}
.forExamSettingDialog .el-dialog__body{
  padding-top: 0px;
}
.forExamSettingDialog .el-dialog__header{
  padding-top: 0px;
  padding-right: 0px;
}
.el-step__title{
  font-size: 14px;
}
.el-card__body{
  padding: 0px 0px 0px 0px;
}
.el-tag {
  margin-right:10px;
  margin-top: 10px;
}
.button-new-tag {
  margin-top: 10px;
  margin-left: 3px;
  width: 150px;
  height: 32px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 150px;
  height: 32px;
  margin-top: 10px;
  vertical-align: bottom;
}

.el-input-group__prepend {
  padding: 0px;
}
.el-input-group__prepend .el-input__wrapper{
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.subjectSwitch{
  padding-bottom: 5px;
}
.settingCard{
  margin-top: 20px;
  padding:15px 10px;
  /*background-color: #faf9f9;*/
  border-radius: 3px;
}
.el-descriptions__body{
  background-color: unset;
}
.noticeCard{
  position: relative;
  padding-bottom: 10px;
}
.noticeCard > .el-card__body{
  padding: var(--el-card-padding);
}
.el-form-item__content{
  line-height: unset;
}
.w-e-select-list{
  max-height: 180px;
}
.markPopperClass > .el-popover__title{
  font-size: 14px;
}
.mixRoomMulSelect .el-select__tags{
  z-index: 2;
}
.mixRoomMulSelect .el-input-group__append, .mixRoomMulSelect .el-input-group__prepend{
  background-color: #ffffff;
}
</style>
